import React from 'react';
var useDebounce = function (inputValue, timer) {
    var _a = React.useState(inputValue), debouncedValue = _a[0], setDebouncedValue = _a[1];
    React.useEffect(function () {
        var handler = setTimeout(function () {
            setDebouncedValue(inputValue);
        }, timer);
        return function () {
            clearTimeout(handler);
        };
    }, [inputValue, timer]);
    return debouncedValue;
};
export default useDebounce;
