var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useFaqContext } from './Faqs';
import add_icon_normal from '../../images/add_icon_normal.png';
import minus_icon_normal from '../../images/minus_icon-normal.png';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { Box } from '@mui/material';
var FaqQuestion = function (_a) {
    var faqs = _a.faqs;
    var _b = useFaqContext(), expand = _b.expand, indexValue = _b.indexValue, setIndexValue = _b.setIndexValue;
    var faqsKey = 'faqs';
    var widthForImages = window.innerWidth / 95;
    var toggleAccordion = function (id) {
        if (indexValue.includes(id)) {
            setIndexValue(indexValue.filter(function (item) { return item !== id; }));
        }
        else {
            setIndexValue(__spreadArray([id], indexValue, true));
        }
    };
    useEffect(function () {
        if (expand) {
            var allIndexes = faqs
                .map(function (faq) { return faq.id; })
                .filter(function (id) { return id !== undefined; });
            setIndexValue(allIndexes);
        }
    }, [expand]);
    return (_jsx(_Fragment, { children: faqs.map(function (item) {
            var _a, _b;
            var isExpanded = indexValue.find(function (found) { return found === (item === null || item === void 0 ? void 0 : item.id); });
            return (_jsxs(Box, __assign({ className: 'faq-row', p: 2 }, { children: [_jsxs(Box, __assign({ style: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }, id: 'faq-question', onClick: function () { return toggleAccordion(item === null || item === void 0 ? void 0 : item.id); } }, { children: [_jsx(Box, __assign({ fontSize: fontSizeDynamic(20), "data-testid": 'faq-question' }, { children: (item === null || item === void 0 ? void 0 : item.question) ? item === null || item === void 0 ? void 0 : item.question : null })), _jsx(Box, __assign({ className: 'faq-expand-icon' }, { children: isExpanded ? (_jsx("img", { id: 'faq-minus-icon', "data-testid": 'faq-minus-icon', src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx("img", { id: 'faq-add-icon', "data-testid": 'faq-add-icon', src: add_icon_normal, alt: 'Add icon', width: widthForImages, height: widthForImages })) }))] })), isExpanded && (_jsxs("div", __assign({ className: 'faq-answer', style: { fontSize: fontSizeDynamic(16) } }, { children: [(item === null || item === void 0 ? void 0 : item.answer) && _jsx("p", __assign({ id: 'faq-answer' }, { children: item === null || item === void 0 ? void 0 : item.answer })), (item === null || item === void 0 ? void 0 : item.subAnswer) && (_jsx("p", __assign({ id: 'faq-sub-answer' }, { children: item === null || item === void 0 ? void 0 : item.subAnswer }))), (item === null || item === void 0 ? void 0 : item.orderList) && (_jsx("ol", __assign({ id: 'faq-order-list' }, { children: (_a = item === null || item === void 0 ? void 0 : item.orderList) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                    var _a;
                                    return (_jsxs("li", { children: [item.text, _jsx("ul", __assign({ id: 'faq-sub-order-list' }, { children: (_a = item.subList) === null || _a === void 0 ? void 0 : _a.map(function (subItem) { return (_jsx("li", { children: subItem.text }, subItem.id)); }) }))] }, faqsKey + item.id));
                                }) }))), (item === null || item === void 0 ? void 0 : item.bullets) && (_jsx("ul", { children: (_b = item === null || item === void 0 ? void 0 : item.bullets) === null || _b === void 0 ? void 0 : _b.map(function (bullet, index) { return (_jsx("li", { children: bullet }, faqsKey + index)); }) }))] })))] }), item === null || item === void 0 ? void 0 : item.id));
        }) }));
};
export default FaqQuestion;
