var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { Throttle } from '../../utilities/Throttle';
import { WhoWeAreStyle } from './InfoCenter.styled';
import ReactGA from 'react-ga4';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var WhoWeAre = function () {
    var _a = useState(true), renderComponent = _a[0], setRenderComponent = _a[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        var handlePopState = Throttle(function (event) {
            event.preventDefault();
            window.history.go(-2);
        }, 1000);
        window.addEventListener('popstate', handlePopState);
        return function () {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);
    useEffect(function () {
        var _a, _b;
        setRenderComponent((_b = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.candidateInfoCenterJson) === null || _b === void 0 ? void 0 : _b.aboutTandym);
    }, [entitlementsData]);
    var handleLearnMore = function () {
        var url = 'https://tandymgroup.com/about/';
        var windowFeatures = 'width=500,height=350,left=500,top=200';
        window.open(url, '_blank', windowFeatures);
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: 'Who We Are Learn More Button',
        });
    };
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsx(WhoWeAreStyle, { children: _jsxs(Box, __assign({ className: 'banner img-1', id: 'who-we-are-banner' }, { children: [_jsx(Typography, __assign({ variant: 'h2', id: 'who-we-are-heading' }, { children: "Who We Are" })), _jsx(Typography, __assign({ variant: 'h6', id: 'who-we-are-banner-info' }, { children: "At Tandym Group, we offer Human-centered, AI-assisted talent solutions that strengthen teams and drive business forward. We are invested in your long-term success\u2014offering career guidance and support whenever you need it." })), _jsx(Box, __assign({ className: 'action-btns' }, { children: _jsxs(TPButton, __assign({ className: 'candidate-portal-button-clicks', id: 'candidate-who-we-are-learn-more-button', color: 'primary', onClick: handleLearnMore, sx: { fontSize: fontSizeDynamic(14) } }, { children: ["Learn More", _jsx(TPSvgIcon, { resourceName: 'arrow-left-alt' })] })) }))] })) }));
};
export default WhoWeAre;
