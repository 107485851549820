var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useCareerApiContext } from '../careerUtilityFunctions';
import CareerCards from '../CareerCards';
import { appliedJobsCss } from '../careerCSS';
import CustomPagination from '../../../../../tandym-web-common/src/shared/pagination/CustomPagination';
import { useAppSelector } from '../../../store/hook';
import { isMobile } from '../../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
var Applied = function () {
    var _a;
    var _b = useCareerApiContext(), careerSearchData = _b.careerSearchData, pageCount = _b.pageCount, setAvailablePositionsLength = _b.setAvailablePositionsLength;
    var scrollRef = useRef(null);
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var showWhishList = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto.showWishList;
    var mobileshowWhishList = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto.feMobileEntitlement) === null || _a === void 0 ? void 0 : _a.showWishList;
    var rowsPerPage = pageCount === 'all'
        ? (careerSearchData === null || careerSearchData === void 0 ? void 0 : careerSearchData.length) || 0
        : parseInt(pageCount || '10', 10);
    var _c = useState(0), page = _c[0], setPage = _c[1];
    var appliedList = careerSearchData ? careerSearchData : [];
    // Filter the data to show only the jobs that have been applied to
    var appliedListData = useMemo(function () {
        return (appliedList &&
            (appliedList === null || appliedList === void 0 ? void 0 : appliedList.filter(function (career) { return (career === null || career === void 0 ? void 0 : career.applicantStatus) === 'Applied'; })));
    }, [appliedList]);
    useEffect(function () {
        var element = document.getElementById('careerJobMain-header-Image');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [page]);
    useEffect(function () {
        if (pageCount) {
            setPage(0);
        }
    }, [pageCount]);
    var paginatedData = useMemo(function () {
        return appliedListData &&
            (appliedListData === null || appliedListData === void 0 ? void 0 : appliedListData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }, [appliedListData, page, rowsPerPage]);
    useEffect(function () {
        var element = document.getElementById('careerJobMain-header-Image');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [page]);
    useEffect(function () {
        if (appliedListData) {
            setAvailablePositionsLength(appliedListData.length);
        }
    }, [appliedListData]);
    return (_jsxs(_Fragment, { children: [paginatedData && (paginatedData === null || paginatedData === void 0 ? void 0 : paginatedData.length) > 0 ? (paginatedData === null || paginatedData === void 0 ? void 0 : paginatedData.map(function (career) { return (_jsx(Box, __assign({ mb: 2, mr: 4, ref: scrollRef, id: 'applied-tab', sx: { backgroundColor: '#F7F8FA', pb: 1 } }, { children: _jsx(CareerCards, { id: career === null || career === void 0 ? void 0 : career.jobId, title: career === null || career === void 0 ? void 0 : career.jobTitle, dateText: (career === null || career === void 0 ? void 0 : career.datePublished) || '', jobType: career === null || career === void 0 ? void 0 : career.workType, description: career === null || career === void 0 ? void 0 : career.jobDescription, location: career === null || career === void 0 ? void 0 : career.location, imgAltText: 'career Job Img', isFavorite: career === null || career === void 0 ? void 0 : career.wishList, isApplied: (career === null || career === void 0 ? void 0 : career.applicantStatus) === 'Applied', isFilled: (career === null || career === void 0 ? void 0 : career.jobStatus) === 'Filled', showWhishList: isMobile() ? mobileshowWhishList : showWhishList, path: 'applied' }) }), career.jobId)); })) : (_jsx(Typography, __assign({ sx: appliedJobsCss.noJobsFound }, { children: "No Jobs Found" }))), (paginatedData === null || paginatedData === void 0 ? void 0 : paginatedData.length) > 0 && (_jsx(CustomPagination, { count: appliedListData === null || appliedListData === void 0 ? void 0 : appliedListData.length, page: page, rowsPerPage: rowsPerPage, onPageChange: function (_, newPage) { return setPage(newPage); } }))] }));
};
export default Applied;
