var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography, } from '@mui/material';
import { fontSizeDynamic } from '../fontsizeutils/fontSizeUtils';
var FormGroupCheckBox = function (_a) {
    var id = _a.id, arrayList = _a.arrayList, selectedArray = _a.selectedArray, checkBoxHandler = _a.checkBoxHandler, fontSize = _a.fontSize, sx = _a.sx, formGroupProps = _a.formGroupProps, otherProps = _a.otherProps, _b = _a.locations, locations = _b === void 0 ? false : _b, data = _a.data, _c = _a.disabled, disabled = _c === void 0 ? false : _c, stateCity = _a.stateCity, checkBoxSize = _a.checkBoxSize;
    var cityKey = stateCity === 'city' ? 'city' : 'state';
    return (_jsx(_Fragment, { children: arrayList.map(function (item, index) { return (_createElement(FormGroup, __assign({ id: "".concat(id ? id : null, "-checkbox-").concat(item), "data-testid": "".concat(id ? id : null, "-checkbox-").concat(item) }, formGroupProps, { key: index, sx: { pl: 2, pb: 1 }, onChange: checkBoxHandler }),
            _createElement(FormControlLabel, __assign({}, otherProps, { key: index, control: _jsx(Checkbox, { value: item, checked: selectedArray.includes(item) || false, sx: {
                        width: checkBoxSize || '36px',
                        height: checkBoxSize || '36px', // Set height based on size
                    } }), label: _jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx("span", __assign({ style: { paddingLeft: '8px' } }, { children: item })), locations && (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(fontSize ? fontSize : 16), ml: 1 }, { children: "(".concat(data &&
                                data.filter(function (item1) { return item1[cityKey] === item; }).length, ")") })))] })), disabled: disabled, sx: __assign(__assign({}, sx), { '& .MuiFormControlLabel-label': {
                        fontSize: fontSizeDynamic(fontSize ? fontSize : 16),
                        fontWeight: 400,
                    } }) })))); }) }));
};
export default FormGroupCheckBox;
