var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isCollaborativeCoaching: false,
    isPII: false,
    resume: false,
};
var profileSlice = createSlice({
    name: 'userProfile',
    initialState: initialState,
    reducers: {
        setCollaborativeCoaching: function (state, action) {
            state.isCollaborativeCoaching = action.payload.isCollaborativeCoaching;
        },
        setPII: function (state, action) {
            state.isPII = action.payload.isPII;
        },
        setResume: function (state, action) {
            state.resume = action.payload.resume;
        },
    },
});
export var setCollaborativeCoaching = (_a = profileSlice.actions, _a.setCollaborativeCoaching), setPII = _a.setPII, setResume = _a.setResume;
export default profileSlice.reducer;
