var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import './CoachBot.scss';
import { Box, Button, Typography } from '@mui/material';
import TPTextInput from '../../../../tandym-web-common/src/shared/components/textinput/TPTextInput';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { chatTime } from '../../utilities/Time';
import { useShowContext } from '../../pages/RootLayout';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import { useAppSelector } from '../../store/hook';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
var coachBot = 'Coach Bot';
var debounce = function (func, wait) {
    var timeout;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeout);
        timeout = setTimeout(function () { return func.apply(void 0, args); }, wait);
    };
};
var CoachBot = function () {
    var _a = useState([]), messages = _a[0], setMessages = _a[1];
    var _b = useState(''), userInput = _b[0], setUserInput = _b[1];
    var _c = useState(false), isTyping = _c[0], setIsTyping = _c[1];
    var _d = useState([]), chatbotSuggestions = _d[0], setChatbotSuggestions = _d[1];
    var coachBodyRef = useRef(null);
    var _e = useShowContext(), isShow = _e.isShow, setIsShow = _e.setIsShow;
    var authToken = useAppSelector(function (state) { return state.userAuth; }).authToken;
    var _f = useState(false), fullScreen = _f[0], setFullScreen = _f[1];
    var _g = useAPI(), httpGet = _g.httpGet, httpPost = _g.httpPost;
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var _j = useState(true), hasMore = _j[0], setHasMore = _j[1];
    var _k = useState(''), errorMessage = _k[0], setErrorMessage = _k[1];
    var _l = useState(false), sendingMessage = _l[0], setSendingMessage = _l[1];
    var _m = useState(false), postSuccessMessage = _m[0], setPostSuccessMessage = _m[1];
    var pageRef = useRef(1);
    var handleInputChange = function (event) {
        setUserInput(event.target.value);
    };
    var _o = useState(false), inputTextMaxLengthError = _o[0], setInputTextMaxLengthError = _o[1];
    var chatId = '1';
    var fetchChatBotMessages = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var previousHeight, res, newMessages, sortedMessages_1, sortedSuggestions, totalRecords, timeout_1, error_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (!hasMore)
                        return [2 /*return*/];
                    setLoading(true);
                    setUserInput('');
                    setInputTextMaxLengthError(false);
                    previousHeight = ((_a = coachBodyRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) || 0;
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_CHATBOT_GET, { pageNumber: pageRef.current, pageSize: 10, need_suggestion: true })];
                case 2:
                    res = _f.sent();
                    if (!res.error) {
                        newMessages = ((_b = res === null || res === void 0 ? void 0 : res.response) === null || _b === void 0 ? void 0 : _b.conversations) || [];
                        sortedMessages_1 = newMessages.sort(function (a, b) {
                            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                        });
                        sortedSuggestions = ((_c = res === null || res === void 0 ? void 0 : res.response) === null || _c === void 0 ? void 0 : _c.suggestions) || [];
                        setChatbotSuggestions(sortedSuggestions);
                        totalRecords = (_e = (_d = res === null || res === void 0 ? void 0 : res.response) === null || _d === void 0 ? void 0 : _d.pagination) === null || _e === void 0 ? void 0 : _e.totalRecords;
                        pageRef.current += 1;
                        setHasMore(messages.length + (sortedMessages_1 === null || sortedMessages_1 === void 0 ? void 0 : sortedMessages_1.length) < totalRecords);
                        setMessages(function (prevMessages) { return __spreadArray(__spreadArray([], sortedMessages_1, true), prevMessages, true); });
                        setLoading(false);
                        timeout_1 = setTimeout(function () {
                            if (coachBodyRef.current) {
                                var newHeight = coachBodyRef.current.scrollHeight;
                                coachBodyRef.current.scrollTop = newHeight - previousHeight;
                            }
                        }, 0);
                        return [2 /*return*/, function () { return clearTimeout(timeout_1); }];
                    }
                    else {
                        setErrorMessage('Something went wrong please try again later');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _f.sent();
                    console.error('Error fetching chat bot messages', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [hasMore, httpGet, messages.length]);
    var handleScroll = useCallback(debounce(function () {
        if (!coachBodyRef.current)
            return;
        var scrollTop = coachBodyRef.current.scrollTop;
        if (scrollTop === 0) {
            fetchChatBotMessages();
        }
    }, 300), [fetchChatBotMessages]);
    useEffect(function () {
        var element = coachBodyRef.current;
        if (element && hasMore) {
            element.addEventListener('scroll', handleScroll);
        }
        return function () {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, hasMore]);
    useEffect(function () {
        if (isShow) {
            requestAnimationFrame(function () {
                if (coachBodyRef.current) {
                    coachBodyRef.current.scrollTop = coachBodyRef.current.scrollHeight;
                }
            });
        }
    }, [isShow, sendingMessage, postSuccessMessage]);
    useEffect(function () {
        if (!isShow) {
            pageRef.current = 1;
            setMessages([]);
            setHasMore(true);
        }
        else if (authToken && isShow) {
            fetchChatBotMessages();
        }
    }, [authToken, isShow]);
    useEffect(function () {
        var _a;
        if (isShow && messages.length > 0) {
            var lastMessage = messages[messages.length - 1];
            // Only scroll when the latest message is from the user (not when loading history)
            if ((lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.sentBy) === 'human') {
                (_a = coachBodyRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    top: coachBodyRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    }, [messages, isShow]);
    var handleKeyDown = function (event) {
        if (event.key === 'Enter') {
            if (sendingMessage)
                return;
            event.preventDefault();
            handleSendMessage(userInput, false);
        }
    };
    var handleSendMessage = function (message, isSuggestion) { return __awaiter(void 0, void 0, void 0, function () {
        var newMessage, dataObj, res_1, timeout_2, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!message.trim())
                        return [2 /*return*/];
                    setSendingMessage(true);
                    newMessage = {
                        activityId: '',
                        eventDetails: {
                            chatThreadId: chatId,
                            message: message,
                        },
                        communicationChannel: 'chat',
                        sentBy: 'human',
                        createdAt: chatTime(),
                    };
                    setMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newMessage], false); });
                    setIsTyping(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    dataObj = __assign({ chat_thread_id: chatId, message: message, need_suggestion: true }, (isSuggestion && { suggestion_selected: message }));
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_CHATBOT_POST, dataObj, { chatThreadId: chatId })];
                case 2:
                    res_1 = _a.sent();
                    if (!(res_1 === null || res_1 === void 0 ? void 0 : res_1.error)) {
                        timeout_2 = setTimeout(function () {
                            var _a, _b, _c;
                            var botMessage = {
                                activityId: '',
                                eventDetails: {
                                    chatThreadId: chatId,
                                    message: ((_c = (_b = (_a = res_1 === null || res_1 === void 0 ? void 0 : res_1.response) === null || _a === void 0 ? void 0 : _a.conversations[1]) === null || _b === void 0 ? void 0 : _b.eventDetails) === null || _c === void 0 ? void 0 : _c.message) ||
                                        'Sorry, I did not understand that',
                                },
                                communicationChannel: 'chat',
                                sentBy: 'AI',
                                createdAt: chatTime(),
                            };
                            setPostSuccessMessage(true);
                            setMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [botMessage], false); });
                            setUserInput('');
                            return function () { return clearTimeout(timeout_2); };
                        }, 1000);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error sending message', error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setIsTyping(false);
                    setSendingMessage(false);
                    setPostSuccessMessage(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleSuggestionClick = function (event) {
        var suggestion = event.currentTarget.textContent;
        if (suggestion)
            handleSendMessage(suggestion, true);
    };
    var formatTimestamp = function (timestamp) {
        var date = new Date(timestamp);
        if (isNaN(date.getTime())) {
            return timestamp;
        }
        var isDateIncluded = timestamp.includes('-');
        if (isDateIncluded) {
            return "".concat(String(date.getMonth() + 1).padStart(2, '0'), "-").concat(String(date.getDate()).padStart(2, '0'), "-").concat(date.getFullYear(), " ").concat(date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            }));
        }
        else {
            return timestamp;
        }
    };
    var memoizedMessages = useMemo(function () {
        return messages === null || messages === void 0 ? void 0 : messages.map(function (msg, index) {
            var _a;
            return (_jsxs(Box, __assign({ className: "chat-message-container ".concat(msg.sentBy === 'AI' ? 'bot-message-container' : 'user-message-container') }, { children: [(msg === null || msg === void 0 ? void 0 : msg.sentBy) === 'AI' && (_jsx(Box, __assign({ className: 'bot-icon' }, { children: _jsx(TPSvgIcon, { resourceName: 'coach-bot-admin-icon' }) }))), _jsxs(Box, { children: [_jsx(Typography, __assign({ component: 'div', className: "chat-message ".concat(msg.sentBy === 'AI' ? 'bot-message' : 'user-message'), sx: {
                                    fontSize: fontSizeDynamic(18),
                                    wordBreak: 'break-word',
                                    whiteSpace: 'pre-line',
                                }, "aria-multiline": msg.sentBy === 'AI' ? false : true }, { children: (_a = msg === null || msg === void 0 ? void 0 : msg.eventDetails) === null || _a === void 0 ? void 0 : _a.message })), _jsxs(Box, __assign({ className: 'chat-time' }, { children: [_jsx(Typography, __assign({ variant: 'caption', mr: 0.5, fontSize: fontSizeDynamic(14) }, { children: formatTimestamp(msg === null || msg === void 0 ? void 0 : msg.createdAt) })), msg.sentBy !== 'AI' && (_jsx(TPSvgIcon, { resourceName: 'chat-double-tick' }))] }))] })] }), 'chat-msg' + index));
        });
    }, [messages]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ component: 'span', className: 'coachbot-toggle-btn', sx: {
                    right: {
                        xs: '5px',
                        sm: '10px',
                        md: '20px',
                        lg: '20px',
                    },
                    bottom: {
                        xs: '5px',
                        sm: '10px',
                        md: '8px',
                        lg: '20px',
                    },
                }, onClick: function () { return setIsShow(!isShow); } }, { children: isShow ? (_jsx(TPSvgIcon, { resourceName: 'coach-bot-close-icon' })) : (_jsx(TPSvgIcon, { resourceName: 'chat-icon' })) })), isShow && (_jsx(Box, __assign({ className: 'coachbot-overlay' }, { children: _jsxs(Box, __assign({ className: 'coachbot-container', sx: {
                        width: {
                            xs: '80%',
                            sm: fullScreen ? '94%' : '450px', // Apply fullScreen logic for sm and up
                        },
                        height: fullScreen ? '-webkit-fill-available' : 'initial',
                        right: {
                            xs: '2%',
                            sm: '3%',
                            md: '35px',
                            lg: '42px',
                            xl: '46px',
                        },
                        bottom: {
                            xs: '2%',
                            sm: '3%',
                            md: '18px',
                            lg: '40px',
                            xl: '50px',
                        },
                        top: {
                            xs: fullScreen ? '2%' : 'initial',
                            sm: fullScreen ? '3%' : 'initial',
                        },
                        maxWidth: {
                            xs: '100%%',
                            sm: '95%',
                            md: '100%',
                        },
                    } }, { children: [_jsx(Box, __assign({ className: 'coachbot-header' }, { children: _jsxs(Box, __assign({ className: 'coachbot-sub-header' }, { children: [_jsxs(Box, { children: [_jsxs(Box, __assign({ className: 'coachbot-admin' }, { children: [_jsx(TPSvgIcon, { resourceName: 'coach-bot-admin-icon' }), _jsx(Box, { className: 'coachbot-admin-status' })] })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(20), fontWeight: 400 }, { children: "Tandym AI Coach" }))] }), _jsxs(Box, __assign({ className: 'coachbot-options', display: 'flex' }, { children: [_jsx(Box, { children: !fullScreen ? (_jsx(OpenInFullIcon, { onClick: function () { return setFullScreen(true); }, sx: { fontSize: fontSizeDynamic(24) } })) : (_jsx(CloseFullscreenIcon, { onClick: function () { return setFullScreen(false); }, sx: { fontSize: fontSizeDynamic(24) } })) }), _jsx(CloseIcon, { onClick: function () { return setIsShow(false); }, sx: { fontSize: fontSizeDynamic(24) } })] }))] })) })), _jsxs(Box, __assign({ className: 'coachbot-body', ref: coachBodyRef, sx: {
                                height: {
                                    xs: fullScreen ? 'calc(100% - 267px)' : '240px',
                                    sm: fullScreen ? 'calc(100% - 320px)' : '320px',
                                    xl: fullScreen ? 'calc(100% - 280px)' : '280px',
                                },
                            } }, { children: [loading && !errorMessage && !sendingMessage && (_jsx(Typography, __assign({ sx: { textAlign: 'center' } }, { children: "Loading History..." }))), errorMessage ? (_jsx(Typography, __assign({ sx: { textAlign: 'center' } }, { children: errorMessage }))) : messages.length === 0 && !loading ? (_jsx(Typography, __assign({ sx: { textAlign: 'center' } }, { children: "No chat history found" }))) : (_jsx(_Fragment, { children: memoizedMessages })), isTyping && (_jsxs(Box, __assign({ className: 'chat-message-container bot-message-container', sx: { display: 'flex' } }, { children: [_jsx(Box, __assign({ className: 'bot-icon' }, { children: _jsx(TPSvgIcon, { resourceName: 'coach-bot-admin-icon' }) })), _jsx(Box, { children: _jsx(Typography, __assign({ className: 'chat-message bot-message' }, { children: _jsxs(Typography, __assign({ component: 'span', className: 'typing-dots' }, { children: [_jsx(Typography, __assign({ component: 'span', sx: { fontSize: fontSizeDynamic(36) } }, { children: "\u2022" })), _jsx(Typography, __assign({ component: 'span', sx: { fontSize: fontSizeDynamic(36) } }, { children: "\u2022" })), _jsx(Typography, __assign({ component: 'span', sx: { fontSize: fontSizeDynamic(36) } }, { children: "\u2022" }))] })) })) })] })))] })), _jsx(Box, __assign({ className: 'coachbot-suggestions' }, { children: _jsx("ul", { children: chatbotSuggestions === null || chatbotSuggestions === void 0 ? void 0 : chatbotSuggestions.map(function (suggestion, index) { return (_jsx("li", __assign({ style: {
                                        fontSize: fontSizeDynamic(16),
                                        fontWeight: 500,
                                    }, onClick: handleSuggestionClick }, { children: suggestion }), coachBot + index)); }) }) })), _jsx(Box, __assign({ className: 'coachbot-input-container' }, { children: _jsxs(Box, __assign({ className: 'coachbot-input' }, { children: [_jsx(TPTextInput, { placeholder: 'Type something\u2026', multiline: true, disabled: sendingMessage, rows: 3, isSpecialCharRequired: false, value: userInput, autoFocus: true, lengthStatus: false, onKeyDown: handleKeyDown, onChange: function (e) {
                                            handleInputChange(e);
                                            if (e.target.value.length <= 5000) {
                                                setInputTextMaxLengthError(false);
                                            }
                                            else {
                                                setInputTextMaxLengthError(true);
                                            }
                                        }, id: 'coachbot-input', inputProps: {
                                            'data-testid': 'coachbot-input',
                                            style: {
                                                fontSize: fontSizeDynamic(18),
                                            },
                                        } }), inputTextMaxLengthError && (_jsx(Typography, __assign({ id: 'coatchbot-input-error-message', "data-testid": 'coatchbot-input-error-message', fontSize: fontSizeDynamic(16), sx: { color: '#C00000' } }, { children: "Exceeded max length of 5000 characters" }))), _jsx(Button, __assign({ disabled: !userInput || sendingMessage || userInput.length > 5000, className: 'coachbot-send-button', onClick: function () { return handleSendMessage(userInput, false); } }, { children: _jsx(TPSvgIcon, { resourceName: 'coach-bot-send-icon' }) }))] })) }))] })) })))] }));
};
export default CoachBot;
