var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../../tandym-web-common/src/components/Header';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../store/hook';
import TPProgressModal from '../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { setLogOut } from '../store/authSlice';
import { isMobile, isMobileEntitlementFlag, } from '../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
import CoachBot from '../components/coachbot/CoachBot';
export var ShowContext = createContext(undefined);
var ShowProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isShow = _b[0], setIsShow = _b[1];
    return (_jsx(ShowContext.Provider, __assign({ value: { isShow: isShow, setIsShow: setIsShow } }, { children: children })));
};
export var useShowContext = function () {
    var context = useContext(ShowContext);
    if (!context) {
        throw new Error('useShowContext must be used within a ShowProvider');
    }
    return context;
};
var RootLayout = function () {
    var _a, _b, _c, _d, _e;
    var location = useLocation();
    var _f = useState([]), tabs = _f[0], setTabs = _f[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _g = useAppSelector(function (state) { return state.userAuth; }), firstName = _g.firstName, lastName = _g.lastName;
    var dispatch = useAppDispatch();
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var logoutHandler = function () {
        dispatch(setLogOut());
    };
    useEffect(function () {
        if (location.pathname.includes('/home')) {
            setLoading(true);
            setTimeout(function () {
                setLoading(false);
            }, 3000);
        }
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'Home',
                    path: '/home',
                    show: true,
                },
                {
                    label: 'Profile',
                    path: '/profile/personal-details',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.profile &&
                            isMobileEntitlementFlag('profile', entitlementsData)
                        : entitlementsData.entitlementDto.profile,
                },
                {
                    label: 'Placements',
                    path: '/placements/placements-details',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.placement &&
                            isMobileEntitlementFlag('placement', entitlementsData)
                        : entitlementsData.entitlementDto.placement,
                },
                {
                    label: 'Find Jobs',
                    path: '/career',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.careerjobssearch &&
                            isMobileEntitlementFlag('careerjobssearch', entitlementsData)
                        : entitlementsData.entitlementDto.careerjobssearch,
                },
                {
                    label: 'Time and Expenses',
                    path: '/timesheets-expenses',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.timesheets &&
                            isMobileEntitlementFlag('timesheets', entitlementsData)
                        : entitlementsData.entitlementDto.timesheets,
                },
                {
                    label: 'Pay',
                    path: '/pay',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.pay &&
                            isMobileEntitlementFlag('pay', entitlementsData)
                        : entitlementsData.entitlementDto.pay,
                },
                {
                    label: 'Learning',
                    path: '/learning/learning-pathways',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.learning &&
                            isMobileEntitlementFlag('learning', entitlementsData)
                        : entitlementsData.entitlementDto.learning,
                },
                {
                    label: 'Info Center',
                    path: '/info-center/who-we-are',
                    show: isMobile()
                        ? entitlementsData.entitlementDto.infocenter &&
                            isMobileEntitlementFlag('infocenter', entitlementsData)
                        : entitlementsData.entitlementDto.infocenter,
                },
            ]);
        }
    }, [entitlementsData]);
    return (_jsx(ShowProvider, { children: _jsxs(Box, { children: [location.pathname !== '/login' && (_jsx(Header, { portal: 'candidate', headerTabs: tabs, setLogout: logoutHandler, firstName: firstName, lastName: lastName })), _jsx(TPProgressModal, { isLoading: loading }), _jsx(Box, __assign({ component: 'main', sx: {
                        flexGrow: 1,
                        width: '100%',
                        position: 'absolute',
                        backgroundColor: '#F6F6F6',
                    } }, { children: _jsx(Outlet, {}) })), location.pathname !== '/login' &&
                    ((!isMobile() &&
                        ((_b = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.learningJson) === null || _b === void 0 ? void 0 : _b.coachBot)) ||
                        (isMobile() &&
                            ((_e = (_d = (_c = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _c === void 0 ? void 0 : _c.feMobileEntitlement) === null || _d === void 0 ? void 0 : _d.learningJson) === null || _e === void 0 ? void 0 : _e.coachBot))) ? (_jsx(CoachBot, {})) : null] }) }));
};
export default RootLayout;
