var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createContext, useContext } from 'react';
export var CareerApiContext = createContext({
    careerSearchData: [],
    pageCount: '10',
    setAvailablePositionsLength: function () { },
});
export var useCareerApiContext = function () {
    var context = useContext(CareerApiContext);
    if (!context) {
        throw new Error('useCandidateApiContext must be used within a CandidateApiProvider');
    }
    return context;
};
export var sortOptions = [
    'Recent Posts',
    'Older Posts',
    'Alphabetical -- A-Z',
    'Alphabetical -- Z-A',
    'Salary -- High to low',
    'Salary -- Low to high',
];
// sorting values based on the selected sorting value Recent Posts, Older Posts, Alphabetical -- A-Z, Alphabetical -- Z-A, Salary -- High to low, Salary -- low to high
export var sortingValues = function (sortingValue, setCareerSearchData, careerSearchData) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h;
    if (careerSearchData === void 0) { careerSearchData = []; }
    if (!sortingValue ||
        !setCareerSearchData ||
        !Array.isArray(careerSearchData)) {
        console.error('Invalid arguments provided to sortingValues function');
        return;
    }
    var sortFunctions = (_a = {},
        _a[(_b = sortOptions[0]) === null || _b === void 0 ? void 0 : _b.toUpperCase()] = function (a, b) {
            var _a, _b;
            return new Date((_a = b === null || b === void 0 ? void 0 : b.datePublished) !== null && _a !== void 0 ? _a : 0).getTime() -
                new Date((_b = a === null || a === void 0 ? void 0 : a.datePublished) !== null && _b !== void 0 ? _b : 0).getTime();
        },
        _a[(_c = sortOptions[1]) === null || _c === void 0 ? void 0 : _c.toUpperCase()] = function (a, b) {
            var _a, _b, _c, _d;
            return ((_b = new Date((_a = a === null || a === void 0 ? void 0 : a.datePublished) !== null && _a !== void 0 ? _a : '')) === null || _b === void 0 ? void 0 : _b.getTime()) -
                ((_d = new Date((_c = b === null || b === void 0 ? void 0 : b.datePublished) !== null && _c !== void 0 ? _c : '')) === null || _d === void 0 ? void 0 : _d.getTime());
        },
        _a[(_d = sortOptions[2]) === null || _d === void 0 ? void 0 : _d.toUpperCase()] = function (a, b) { var _a; return (_a = a === null || a === void 0 ? void 0 : a.jobTitle) === null || _a === void 0 ? void 0 : _a.localeCompare(b.jobTitle); },
        _a[(_e = sortOptions[3]) === null || _e === void 0 ? void 0 : _e.toUpperCase()] = function (a, b) { var _a; return (_a = b === null || b === void 0 ? void 0 : b.jobTitle) === null || _a === void 0 ? void 0 : _a.localeCompare(a === null || a === void 0 ? void 0 : a.jobTitle); },
        _a[(_f = sortOptions[4]) === null || _f === void 0 ? void 0 : _f.toUpperCase()] = function (a, b) { return (b === null || b === void 0 ? void 0 : b.salary) - (a === null || a === void 0 ? void 0 : a.salary); },
        _a[(_g = sortOptions[5]) === null || _g === void 0 ? void 0 : _g.toUpperCase()] = function (a, b) { return (a === null || a === void 0 ? void 0 : a.salary) - (b === null || b === void 0 ? void 0 : b.salary); },
        _a);
    var sortFunction = sortFunctions[sortingValue === null || sortingValue === void 0 ? void 0 : sortingValue.toUpperCase()];
    if (sortFunction) {
        setCareerSearchData((_h = __spreadArray([], careerSearchData, true)) === null || _h === void 0 ? void 0 : _h.sort(sortFunction));
    }
    else {
        console.warn("No sort function found for sorting value: ".concat(sortingValue));
        setCareerSearchData(careerSearchData);
    }
};
// common search filter function to search the data based on the search value and keys
export var commonInputSearch = function (data, keys, searchValue) {
    if (!Array.isArray(data) ||
        !Array.isArray(keys) ||
        typeof searchValue !== 'string') {
        console.error('Invalid arguments provided to commonInputSearch function');
        return [];
    }
    if (!(searchValue === null || searchValue === void 0 ? void 0 : searchValue.trim())) {
        return data;
    }
    var filteredData = data.filter(function (item) {
        return keys.some(function (key) {
            var _a, _b, _c;
            if (item[key]) {
                return (_c = (_b = (_a = item[key]) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === null || _c === void 0 ? void 0 : _c.includes(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase());
            }
            return false;
        });
    });
    return filteredData;
};
// common filter function to filter the data based on the selected values and get the values for the selected key
export var uniqueValues = function (array, key) {
    var _a;
    if (!Array.isArray(array) || typeof key !== 'string') {
        console.error('Invalid arguments provided to uniqueValues function');
        return [];
    }
    return (_a = array === null || array === void 0 ? void 0 : array.map(function (item) { return item[key]; })) === null || _a === void 0 ? void 0 : _a.filter(function (value, index, self) { return value !== null && (self === null || self === void 0 ? void 0 : self.indexOf(value)) === index; });
};
// filter the data based on the selected values
export var filterByValues = function (data, values, key) {
    if (!Array.isArray(data) ||
        !Array.isArray(values) ||
        typeof key !== 'string') {
        console.error('Invalid arguments provided to filterByValues function');
        return [];
    }
    return (values === null || values === void 0 ? void 0 : values.length) > 0
        ? data === null || data === void 0 ? void 0 : data.filter(function (item) {
            var itemValue = item[key];
            return ((itemValue !== null &&
                itemValue !== undefined &&
                values.includes(itemValue)) ||
                values.includes(''));
        })
        : data;
};
export var removeDotFromStringEnd = function (item) {
    return item.endsWith('.') ? item.slice(0, -1) : item;
};
