var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isLoggedIn: false,
    candidateId: null,
    userId: null,
    authToken: null,
    email: null,
    isEmailLinkExpired: false,
    firstName: null,
    lastName: null,
    googleTrackingId: null,
    isTermsAccepted: false,
    NajaxaTimeSheetURL: null,
    paycheckURL: null,
    taskHumanURL: null,
};
var authSlice = createSlice({
    name: 'userAuth',
    initialState: initialState,
    reducers: {
        setLogIn: function (state, action) {
            return __assign(__assign({}, state), action.payload);
        },
        setLogOut: function (state) {
            return __assign({}, initialState);
        },
    },
});
export var setLogIn = (_a = authSlice.actions, _a.setLogIn), setLogOut = _a.setLogOut;
export default authSlice.reducer;
