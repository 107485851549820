var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import './Header.scss';
import Tabs from './Tabs';
import TPSvgIcon from '../shared/components/svg-icon/TPSvgIcon';
import ReactGA from 'react-ga4';
import { Drawer, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import quizImg from '../images/quiz.png';
import Avatar from '@mui/material/Avatar';
import { fontSizeDynamic } from '../shared/components/fontsizeutils/fontSizeUtils';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from './sidebar/SideBar';
import { generateUserInitials } from '../shared/utilities/Candidate';
var Header = function (props) {
    var headerTabs = props.headerTabs, portal = props.portal, setLogout = props.setLogout, firstName = props.firstName, lastName = props.lastName;
    var navigate = useNavigate();
    var _a = useState(false), isShowSidebar = _a[0], setIsShowSidebar = _a[1];
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var initials = generateUserInitials(firstName, lastName);
    var widthAndHeightForImages = window.innerWidth / 50;
    var isScreenSmall = window.innerWidth <= 900;
    var isMobileScreen = window.innerWidth <= 768;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var handleLogout = function () {
        setLogout();
        sessionStorage.clear();
        navigate('/login');
    };
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: "".concat(portal),
            action: 'Click',
            label: "".concat(portal, " ").concat(label, " tab"),
        });
    };
    var handleFaqClick = function () {
        navigate('/faqs');
    };
    var isCandidateMobile = isMobileScreen && portal === 'candidate';
    var RightAlignIcons = function () {
        return (_jsxs(Box, __assign({ className: 'icon-align' }, { children: [!isMobileScreen && (_jsx(Link, __assign({ to: '/faqs' }, { children: _jsx(IconButton, __assign({ id: "".concat(portal, "-faqs-icon"), "data-testid": "".concat(portal, "-faqs-icon"), className: "".concat(portal, "-faqs-icon candidate-portal-button-clicks"), onClick: handleFaqClick }, { children: _jsx("img", { src: quizImg, className: "".concat(portal, "-faqs-icon candidate-portal-button-clicks"), id: "".concat(portal, "-faqs-icon"), alt: 'quiz-img', width: isScreenSmall ? 24 : widthAndHeightForImages, height: isScreenSmall ? 24 : widthAndHeightForImages }) })) }))), _jsx(Avatar, __assign({ className: 'user-icon-avatar', sx: {
                        fontSize: isScreenSmall ? 12 : fontSizeDynamic(16),
                        width: isScreenSmall ? 24 : widthAndHeightForImages,
                        height: isScreenSmall ? 24 : widthAndHeightForImages,
                    }, onClick: handleClick }, { children: initials })), _jsx(Popover, __assign({ disableScrollLock: true, className: 'logout-popover', open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    } }, { children: _jsx(Typography, __assign({ sx: {
                            p: 2,
                            fontSize: fontSizeDynamic(18),
                        }, onClick: handleLogout }, { children: "Logout" })) }))] })));
    };
    var TandymLogo = function () {
        return (_jsxs(IconButton, __assign({ sx: {
                mr: isScreenSmall ? 0 : 2,
                display: 'flex',
                flexDirection: 'column',
            }, id: 'tandym-logo', "data-testid": 'tandym-logo' }, { children: [_jsx(TPSvgIcon, { resourceName: 'tandym-logo-icon' }), _jsx(Typography, __assign({ className: 'headerMain-env', id: 'logout', "data-testid": 'logout', sx: {
                        ml: 'auto',
                        fontSize: fontSizeDynamic(21),
                    } }, { children: env === null || env === void 0 ? void 0 : env.toLocaleUpperCase() }))] })));
    };
    var handleSidebarOpen = function () {
        setIsShowSidebar(true);
    };
    var handleSidebarClose = function () {
        setIsShowSidebar(false);
    };
    var env = window._env_.REACT_APP_ENV || process.env.REACT_APP_ENV;
    return (_jsxs(Box, __assign({ sx: { flexGrow: 1 }, id: 'header-box', "data-testid": 'header-box' }, { children: [_jsxs(AppBar, __assign({ position: 'static', className: 'headerMain' }, { children: [!isScreenSmall && (_jsxs(Toolbar, __assign({ sx: { justifyContent: 'space-between' } }, { children: [_jsx(Box, __assign({ className: 'headerMain-logo' }, { children: TandymLogo() })), _jsx(Box, { children: _jsx(Tabs, { headerTabs: headerTabs, tabSize: 'large', parentPath: 'header', tabClick: handleClickedTab, portal: portal }) }), _jsx(Box, { children: RightAlignIcons() })] }))), isScreenSmall && (_jsxs(Toolbar, __assign({ sx: { justifyContent: 'space-between' } }, { children: [_jsxs(Box, __assign({ display: 'flex', alignItems: 'baseline' }, { children: [_jsx(IconButton, __assign({ sx: { color: 'white', fontSize: fontSizeDynamic(24) }, onClick: handleSidebarOpen }, { children: _jsx(MenuIcon, { sx: { color: '#000' } }) })), TandymLogo()] })), _jsx(Box, { children: RightAlignIcons() })] })))] })), _jsx(Drawer, __assign({ open: isShowSidebar, onClose: handleSidebarClose }, { children: _jsx(SideBar, { tabs: headerTabs.map(function (tab) {
                        var _a;
                        return (__assign(__assign({}, tab), { path: tab.path || '', show: (_a = tab.show) !== null && _a !== void 0 ? _a : false }));
                    }), setIsShowSidebar: setIsShowSidebar, handleLogout: handleLogout }) }))] })));
};
export default Header;
