export var NoticePeriodType;
(function (NoticePeriodType) {
    NoticePeriodType[NoticePeriodType["Day"] = 0] = "Day";
    NoticePeriodType[NoticePeriodType["Week"] = 1] = "Week";
    NoticePeriodType[NoticePeriodType["Month"] = 2] = "Month";
})(NoticePeriodType || (NoticePeriodType = {}));
export var skillAcronyms = {
    'Advanced Practice & Physicians': 'APP',
    'Nursing & Therapy': 'NURSING',
    'Clinical Professionals': 'CLINPROF',
    'Enterprise Applications': 'ENTPAPP',
    'Architecture Development & QA': 'ARCDEVQA',
    'Program & Project Management': 'PGPJM',
    'Corporate Services': 'CORPSERV',
    'Corporate Finance': 'CORPFIN',
    'Data Center': 'DATAC',
    Infrastructure: 'INFRAST',
};
