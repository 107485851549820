var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useCallback } from 'react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import './SkillsList.scss';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import { skillsCss } from './SkillsListCss';
import { useCandidateApiContext } from '../Profile';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { TANDYM_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../../../tandym-web-common/src/shared/services/api/API';
import DeleteConfirmationModal from './deleteSkill/DeleteSkill';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import TPToast from '../../../../../tandym-web-common/src/shared/components/toast/TPToast';
import NavigationGuard from '../navigationGuard/navigationGuard';
import { useNavigate } from 'react-router-dom';
import NoPageFound from '../../../../../tandym-web-common/src/components/NoPageFound';
import { setTabNavigationGuard, setNavigationGuardModalPopup, setNavigationHistory, } from '../../../store/tabNavigationGuard';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Skills = function () {
    var navigation = useNavigate();
    var dispatch = useAppDispatch();
    var skillsId = 'profile-skills';
    var _a = useCandidateApiContext(), profileData = _a.profileData, refreshProfile = _a.refreshProfile;
    var _b = useAppSelector(function (state) { return state.tabNavigationGuard; }), navigationGuardModalPopup = _b.navigationGuardModalPopup, navigationHistory = _b.navigationHistory;
    var _c = useState([]), skillsList = _c[0], setSkillsList = _c[1];
    var _d = useState([]), originalSkillsList = _d[0], setOriginalSkillsList = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState(''), successMessage = _f[0], setSuccessMessage = _f[1];
    var _g = useState(''), errorMessage = _g[0], setErrorMessage = _g[1];
    var _h = useState(false), isDeleteConfirmModalVisible = _h[0], setIsDeleteConfirmModalVisible = _h[1];
    var _j = useState(null), skillToDelete = _j[0], setSkillToDelete = _j[1];
    var _k = useState(true), btnDisabled = _k[0], setBtnDisabled = _k[1];
    var _l = useState(true), btnDisabledDiscard = _l[0], setBtnDisabledDiscard = _l[1];
    var _m = useState(false), open = _m[0], setOpen = _m[1];
    var _o = useState(''), inputValue = _o[0], setInputValue = _o[1];
    var _p = useState([]), filteredSuggestions = _p[0], setFilteredSuggestions = _p[1];
    var _q = useState(true), renderComponent = _q[0], setRenderComponent = _q[1];
    var _r = useState(false), showToast = _r[0], setShowToast = _r[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var windowWidth = window.innerWidth / 60;
    useEffect(function () {
        var _a, _b;
        setRenderComponent((_b = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.profileJson) === null || _b === void 0 ? void 0 : _b.skills);
    }, [entitlementsData]);
    useEffect(function () {
        if (profileData && profileData.skills) {
            var skills = profileData.skills;
            setSkillsList(skills);
            setOriginalSkillsList(__spreadArray([], skills, true));
            var mappedSuggest = skills.map(function (sug) { return ({
                label: sug.skill.name,
                value: sug.skill.id,
            }); });
            setFilteredSuggestions(mappedSuggest);
        }
    }, [profileData]);
    var httpPost = useAPI().httpPost;
    var candidateId = useAppSelector(function (state) { return state.userAuth; }).candidateId;
    useEffect(function () {
        var timeOut = setTimeout(function () {
            setErrorMessage('');
            setSuccessMessage('');
        }, 3000);
        return function () { return clearTimeout(timeOut); };
    }, [successMessage, errorMessage]);
    var handleDelete = useCallback(function (skill) {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: 'Delete skill icon',
        });
        // To prevent the user from navigating away from the page without saving the changes
        dispatch(setTabNavigationGuard({
            mainTabNavigationGuard: true,
        }));
        setSkillToDelete(skill);
        setIsDeleteConfirmModalVisible(true);
        setBtnDisabled(false);
    }, []);
    var handleDeleteConfirm = function () {
        if (skillToDelete) {
            setSkillsList(function (prevSkillsList) {
                var previosSkillsListdelete = prevSkillsList.filter(function (skill) { return skill.skill.id !== skillToDelete.skill.id; });
                var mappedSuggest = previosSkillsListdelete.map(function (sug) { return ({ label: sug.skill.name, value: sug.skill.id }); });
                setFilteredSuggestions(mappedSuggest);
                setBtnDisabledDiscard(false);
                setSuccessMessage('Skills deleted successfully. Please click on Save Skills Button.');
                return previosSkillsListdelete;
            });
        }
        setIsDeleteConfirmModalVisible(false);
    };
    var isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z|[+-]\d{2}:\d{2})$/;
    function isIsoDate(value) {
        return isoDatePattern.test(value);
    }
    var discardBtnHandler = function () {
        var skillfilter = originalSkillsList.filter(function (sug) { return sug.skill; });
        var mapped = function (skillSuggestions) {
            return skillSuggestions.map(function (suggestion) { return ({
                label: suggestion.skill.name,
                value: suggestion.skill.id,
            }); });
        };
        var mappedSuggestions = mapped(skillfilter);
        setFilteredSuggestions(mappedSuggestions);
        setSkillsList(__spreadArray([], originalSkillsList, true)); // Restore the original skills list
        setBtnDisabled(true);
        setBtnDisabledDiscard(true); // Disable the save button as there are no unsaved changes
        dispatch(setNavigationGuardModalPopup({
            navigationGuardModalPopup: false,
        }));
        dispatch(setTabNavigationGuard({
            mainTabNavigationGuard: false,
        }));
        dispatch(setNavigationHistory({
            navigationHistory: '',
        }));
        setShowToast(true);
        var toastTimeout = setTimeout(function () {
            setShowToast(false);
        }, 3000);
        return function () { return clearTimeout(toastTimeout); };
    };
    var postSkillsData = function (data, isFrom) { return __awaiter(void 0, void 0, void 0, function () {
        var UpdateCandidateProfileURL, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isFrom === 'save' &&
                        dispatch(setNavigationHistory({
                            navigationHistory: '',
                        }));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    UpdateCandidateProfileURL = TANDYM_CONSTANTS.API_URLS.CANDIDATE_ADD_SKILL;
                    return [4 /*yield*/, httpPost(UpdateCandidateProfileURL, data)];
                case 2:
                    response = _a.sent();
                    refreshProfile();
                    setOriginalSkillsList(response.response);
                    setSkillsList(response.response);
                    setSuccessMessage(response.message);
                    setBtnDisabled(true);
                    setBtnDisabledDiscard(true);
                    if (!response.error) {
                        dispatch(setNavigationGuardModalPopup({
                            navigationGuardModalPopup: false,
                        }), setTabNavigationGuard({
                            mainTabNavigationGuard: false,
                        }));
                    }
                    if (navigationHistory && navigationGuardModalPopup) {
                        navigation(isFrom === 'save' ? '' : navigationHistory);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setErrorMessage(String(error_1));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var saveBtnHandler = function (isFrom) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedSkillsList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ReactGA.event({
                        category: 'Candidate',
                        action: 'Click',
                        label: 'Save Skills Button',
                    });
                    setIsLoading(true);
                    formattedSkillsList = skillsList &&
                        (skillsList === null || skillsList === void 0 ? void 0 : skillsList.map(function (skill) { return ({
                            skillLevelId: isIsoDate(skill.skillLevelId) ? null : skill.skillLevelId,
                            candidateId: candidateId,
                            skill: { id: skill.skill.id, name: skill.skill.name },
                            proficiency: null,
                            experience: 0,
                            experiencePeriod: null,
                            rowVersion: skill.rowVersion || null,
                        }); }));
                    return [4 /*yield*/, postSkillsData(formattedSkillsList, isFrom)];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var addNewSkill = function (event, newValue) {
        event.preventDefault();
        if (event.key === 'Escape') {
            setInputValue('');
        }
        if (inputValue.length > 0 && newValue) {
            setBtnDisabled(false);
            setBtnDisabledDiscard(false);
            dispatch(setTabNavigationGuard({
                mainTabNavigationGuard: true,
            }));
            if (newValue) {
                var isSkillAdded = filteredSuggestions.some(function (skill) { return skill.label === newValue.value; });
                if (!isSkillAdded) {
                    setInputValue(''); // Clears the input value when a skill is selected
                    var newSkill = {
                        skillLevelId: new Date().toISOString(),
                        skill: { id: newValue.value, name: newValue.label },
                        proficiency: {
                            id: '143570000',
                            name: 'Basic',
                        },
                        experience: 1,
                        experiencePeriod: {
                            id: '143570000',
                            name: 'Month',
                        },
                        rowVersion: '',
                    };
                    var updatedSkills = __spreadArray([newSkill], skillsList, true);
                    var skillssug = updatedSkills.filter(function (sug) { return sug.skill; });
                    var mapped = function (skillSuggestions) {
                        return skillSuggestions.map(function (suggestion) { return ({
                            label: suggestion.skill.name,
                            value: suggestion.skill.id,
                        }); });
                    };
                    var mappedSuggestions = mapped(skillssug);
                    setFilteredSuggestions(mappedSuggestions);
                    setSuccessMessage('Skills added successfully. Please click on Save Skills Button.');
                    setSkillsList(updatedSkills);
                }
            }
        }
    };
    useEffect(function () {
        var updateFilteredSuggestions = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, response, skillSuggestions, filt, newFilteredSuggestions, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!inputValue) return [3 /*break*/, 6];
                        setOpen(true);
                        data = { QueryString: inputValue };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_TYPE_HEAD_SKILLS_GET, data)];
                    case 2:
                        response = _a.sent();
                        if (response.skills) {
                            setOpen(true);
                            skillSuggestions = response.skills;
                            filt = skillSuggestions.map(function (suggestion) { return ({
                                label: suggestion.name,
                                value: suggestion.id,
                            }); });
                            newFilteredSuggestions = filt.filter(function (sugges) {
                                return !filteredSuggestions.some(function (filteredSuggestion) {
                                    return filteredSuggestion.value === sugges.value;
                                });
                            });
                            if (newFilteredSuggestions.length > 0) {
                                setFilteredSuggestions(newFilteredSuggestions);
                            }
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        console.error('Error fetching skill suggestions:', error_2);
                        return [3 /*break*/, 5];
                    case 4:
                        setOpen(false);
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        setFilteredSuggestions([]);
                        setOpen(false);
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); }, 500);
        return function () {
            clearTimeout(updateFilteredSuggestions); // memory cleanup. MUST
        };
    }, [inputValue]);
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsxs(Box, __assign({ sx: { px: '5px', py: '10px' } }, { children: [_jsx(TPToast, { message: successMessage, open: successMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'success' }), !navigationGuardModalPopup && (_jsx(TPToast, { message: errorMessage, open: errorMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'error' })), showToast && (_jsx(TPToast, { message: 'Your changes has been discarded', open: showToast, vertical: 'top', horizontal: 'center', type: 'info' })), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ style: { color: colorCodes.textColors.black }, className: 'skillsHeader', id: "".concat(skillsId, "-header-text"), fontSize: fontSizeDynamic(24) }, { children: "Add skills" })), _jsx(Typography, __assign({ style: { color: colorCodes.textColors.black }, className: 'descriptionText', id: "".concat(skillsId, "-description-text"), fontSize: fontSizeDynamic(14), marginY: 2 }, { children: "Adding your skills help your profile align with what exactly recruiters are looking for." }))] })), _jsx(Grid, __assign({ item: true, className: 'tableGrid' }, { children: _jsxs(TableContainer, __assign({ component: Paper }, { children: [_jsx(Autocomplete, { id: 'autocomplete-skill', "data-testid": 'autocomplete-skill', value: inputValue.length > 0
                                ? { label: inputValue, value: inputValue }
                                : null, filterOptions: function () {
                                return filteredSuggestions.filter(function (options) {
                                    return (options.label
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase()) &&
                                        !skillsList.some(function (selectedSkill) { return selectedSkill.skill.id === options.value; }));
                                });
                            }, sx: { fontSize: fontSizeDynamic(16) }, disableCloseOnSelect: false, loading: open, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { placeholder: 'To add a new skill enter skill here', variant: 'standard', onChange: function (event) { return setInputValue(event.target.value); }, sx: { fontSize: fontSizeDynamic(16) }, InputProps: __assign(__assign({}, params.InputProps), { classes: {
                                        input: 'suggester',
                                        root: 'custom-autocomplete-root',
                                    }, endAdornment: (_jsx("div", { className: 'custom-autocomplete-arrow' })), sx: { fontSize: fontSizeDynamic(16), pl: 3 } }) }))); }, onChange: function (e, inputValue) {
                                addNewSkill(e, inputValue);
                            }, freeSolo: inputValue.length > 0 ? true : false, options: filteredSuggestions, clearOnEscape: true, isOptionEqualToValue: function (options, value) {
                                return options.value ===
                                    value.value;
                            } }), _jsx(Table, __assign({ "aria-label": 'simple table' }, { children: _jsx(TableBody, { children: skillsList === null || skillsList === void 0 ? void 0 : skillsList.map(function (item) {
                                    return (_jsxs(TableRow, __assign({ sx: { '&:last-child td, &:last-child th': { border: 0 } } }, { children: [_jsx(TableCell, __assign({ component: 'th', scope: 'row', id: "".concat(skillsId, "-skill"), "data-testid": "".concat(skillsId, "-skill-").concat(item === null || item === void 0 ? void 0 : item.skill.name), sx: skillsCss.tableItemText }, { children: item === null || item === void 0 ? void 0 : item.skill.name })), _jsx(TableCell, __assign({ align: 'center' }, { children: _jsx(Box, __assign({ sx: skillsCss.rowEditAndDelete }, { children: _jsx("div", __assign({ id: "".concat(skillsId, "-delete-icon"), "data-testid": "".concat(skillsId, "-delete-icon"), onClick: function () { return handleDelete(item); }, style: { width: windowWidth, height: windowWidth } }, { children: _jsx(TPSvgIcon, { resourceName: 'Profile-Skills-Delete-Icon' }) })) })) }))] }), "".concat(item === null || item === void 0 ? void 0 : item.skillLevelId, "-").concat(item === null || item === void 0 ? void 0 : item.skill.id)));
                                }) }) }))] })) })), skillsList && skillsList.length <= 0 && (_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ sx: skillsCss.noData, className: 'noDataAvailable', id: "".concat(skillsId, "-description-text") }, { children: "No data available" })) }))), _jsxs(Grid, __assign({ container: true, direction: 'row', justifyContent: 'end', sx: skillsCss.buttonContainer }, { children: [_jsx(TPButton, { id: "".concat(skillsId, "-discard-changes-button"), "data-testid": "".concat(skillsId, "-discard-changes-button"), children: 'Discard Changes', color: 'secondary', onClick: discardBtnHandler, disabled: btnDisabledDiscard, sx: { fontSize: fontSizeDynamic(14) } }), _jsx(TPButton, { className: 'candidate-portal-button-clicks', id: 'save-skill-button', "data-testid": "".concat(skillsId, "-save-button"), children: 'Save Skills', color: 'primary', onClick: function () { return saveBtnHandler('save'); }, disabled: btnDisabled, sx: { fontSize: fontSizeDynamic(14) } })] })), isLoading && _jsx(TPProgressModal, { isLoading: isLoading }), isDeleteConfirmModalVisible && (_jsx(DeleteConfirmationModal, { open: isDeleteConfirmModalVisible, onClose: function () { return setIsDeleteConfirmModalVisible(false); }, onConfirm: handleDeleteConfirm })), navigationGuardModalPopup && (_jsx(NavigationGuard, { open: navigationGuardModalPopup, cancelButton: true, cancel: function () {
                    return dispatch(setNavigationGuardModalPopup({
                        navigationGuardModalPopup: false,
                    }));
                }, saveButton: true, saveAndProceed: function () { return saveBtnHandler('guard'); }, errorMessage: errorMessage, saveAndProceedDisable: btnDisabled }))] })));
};
export default Skills;
