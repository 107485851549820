export var CareerActionTypes;
(function (CareerActionTypes) {
    CareerActionTypes["JOB_STATUS"] = "jobStatus";
    CareerActionTypes["CATEGORY"] = "category";
    CareerActionTypes["STATE"] = "state";
    CareerActionTypes["WORK_TYPE"] = "workType";
    CareerActionTypes["WORK_MODEL"] = "workModel";
    CareerActionTypes["LOCATION"] = "location";
    CareerActionTypes["SEARCH"] = "search";
    CareerActionTypes["CITY"] = "city";
    CareerActionTypes["SUBCATEGORY"] = "subCategory";
})(CareerActionTypes || (CareerActionTypes = {}));
