var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { Box, Checkbox, FormControlLabel, FormGroup, Slider, Typography, } from '@mui/material';
import TPTextInput from '../../../../tandym-web-common/src/shared/components/textinput/TPTextInput';
import { leftSelectionCss } from './careerCSS';
import FormGroupCheckBox from '../../../../tandym-web-common/src/shared/components/checkBox/formGroupCheckBox';
import { CareerActionTypes, } from '../../shared/models/careerInterfaces';
import { filterByValues, removeDotFromStringEnd, uniqueValues, } from './careerUtilityFunctions';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
var CareerLeftSelection = function (_a) {
    var selectedFilterValues = _a.selectedFilterValues, selectedCity = _a.selectedCity, selectedSubCategory = _a.selectedSubCategory, careerSearchData = _a.careerSearchData, filteredDataMemo = _a.filteredDataMemo, salaryValue = _a.salaryValue, searchValue = _a.searchValue, salaryRange = _a.salaryRange, locationValue = _a.locationValue, handleInputKeySearch = _a.handleInputKeySearch, filterHandler = _a.filterHandler, handleSalaryBar = _a.handleSalaryBar, showAndHide = _a.showAndHide, setShowAndHide = _a.setShowAndHide, setSelectedSubCategory = _a.setSelectedSubCategory, setSelectedFilterValues = _a.setSelectedFilterValues, setSelectedCity = _a.setSelectedCity;
    var careerFilter = 'careerFilter';
    var searchDataInSorting = __spreadArray([], careerSearchData, true).sort(function (a, b) {
        return (a[CareerActionTypes.CATEGORY] || '').localeCompare(b[CareerActionTypes.CATEGORY] || '') ||
            (a[CareerActionTypes.STATE] || '').localeCompare(b[CareerActionTypes.STATE] || '') ||
            (a[CareerActionTypes.SUBCATEGORY] || '').localeCompare(b[CareerActionTypes.SUBCATEGORY] || '') ||
            (a[CareerActionTypes.WORK_TYPE] || '').localeCompare(b[CareerActionTypes.WORK_TYPE] || '') ||
            (a[CareerActionTypes.WORK_MODEL] || '').localeCompare(b[CareerActionTypes.WORK_MODEL] || '') ||
            (a[CareerActionTypes.JOB_STATUS] || '').localeCompare(b[CareerActionTypes.JOB_STATUS] || '');
    });
    // filtered the values to get the list of job status
    var jobStatus = uniqueValues(searchDataInSorting, CareerActionTypes.JOB_STATUS);
    // filtered the values to get the list of locations
    var locations = uniqueValues(searchDataInSorting, CareerActionTypes.STATE);
    // filtered the values to get the list of Specializations
    var categoryLabels = uniqueValues(searchDataInSorting, CareerActionTypes.CATEGORY);
    // filtered the values to get the list of job status
    var subCategoryFilters = filterByValues(searchDataInSorting, selectedFilterValues.category, CareerActionTypes.CATEGORY);
    // filtered the values to get the list of job status
    var subCategoryList = uniqueValues(subCategoryFilters, CareerActionTypes.SUBCATEGORY);
    // if category and subcategory are same then add a dot at the end of the category
    var filteredSubCategory = subCategoryList.map(function (item) {
        return categoryLabels.includes(item) ? item + '.' : item;
    });
    // filtered the values to get the list of Work types
    var workType = uniqueValues(searchDataInSorting, CareerActionTypes.WORK_TYPE);
    // filtered the values to get the list of work Models
    var workModel = uniqueValues(searchDataInSorting, CareerActionTypes.WORK_MODEL);
    // filtered the values to get the list of Cities
    var cityFilters = filterByValues(searchDataInSorting, selectedFilterValues.state, CareerActionTypes.STATE);
    // filtered the values to get the list of Cities
    var cities = uniqueValues(cityFilters, CareerActionTypes.CITY);
    var handleShowAndHide = function (value) {
        setShowAndHide(function (prevState) {
            var _a;
            return __assign(__assign({}, prevState), (_a = {}, _a[value] = !prevState[value], _a));
        });
    };
    var cityList = function () {
        var _a;
        return (_jsx(_Fragment, { children: selectedFilterValues &&
                ((_a = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.state) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                cities &&
                (cities === null || cities === void 0 ? void 0 : cities.map(function (item, index) {
                    var _a;
                    return (_jsx(FormGroup, __assign({ sx: { pl: 2, pb: 2 }, onChange: function (e) {
                            return filterHandler(e, CareerActionTypes.CITY);
                        } }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { value: item, checked: (selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.includes(item)) || false, sx: { width: '15px', height: '15px' } }), label: _jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx("span", __assign({ style: { paddingLeft: '8px' } }, { children: item })), locations && (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16), ml: 1 }, { children: "(".concat(careerSearchData &&
                                            ((_a = careerSearchData === null || careerSearchData === void 0 ? void 0 : careerSearchData.filter(function (item1) { return item1[CareerActionTypes.CITY] === item; })) === null || _a === void 0 ? void 0 : _a.length), ")") })))] })), sx: {
                                '& .MuiFormControlLabel-label': {
                                    fontSize: fontSizeDynamic(16),
                                },
                                pl: 2,
                            } }, index) }), index));
                })) }));
    };
    var stateList = function () {
        return (_jsx(_Fragment, { children: locations &&
                (locations === null || locations === void 0 ? void 0 : locations.map(function (item, index) {
                    var _a, _b, _c;
                    return (_jsxs(FormGroup, __assign({ sx: { pl: 4, pb: 1 }, onChange: function (e) {
                            return filterHandler(e, CareerActionTypes.LOCATION);
                        } }, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { value: item, checked: ((_a = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.state) === null || _a === void 0 ? void 0 : _a.includes(item)) || false, sx: { width: '15px', height: '15px' } }), label: _jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx("span", __assign({ style: { paddingLeft: '8px' } }, { children: item })), locations && (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16), ml: 1 }, { children: "(".concat(careerSearchData &&
                                                ((_b = careerSearchData === null || careerSearchData === void 0 ? void 0 : careerSearchData.filter(function (item1) { return item1[CareerActionTypes.STATE] === item; })) === null || _b === void 0 ? void 0 : _b.length), ")") })))] })), inputMode: 'search', sx: {
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: fontSizeDynamic(16),
                                    },
                                } }, index), _jsx(Box, __assign({ pt: 1 }, { children: selectedFilterValues &&
                                    ((_c = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.state) === null || _c === void 0 ? void 0 : _c.includes(item)) &&
                                    cityList() }))] }), index));
                })) }));
    };
    var subCategory = function (category) {
        var _a;
        return (_jsx(_Fragment, { children: selectedFilterValues &&
                ((_a = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.category) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                filteredSubCategory &&
                (filteredSubCategory === null || filteredSubCategory === void 0 ? void 0 : filteredSubCategory.map(function (item, index) {
                    var _a;
                    return (_jsx(FormGroup, __assign({ sx: { pl: 2, pb: 2 }, onChange: function (e) {
                            return filterHandler(e, CareerActionTypes.SUBCATEGORY);
                        } }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { value: item, checked: (selectedSubCategory === null || selectedSubCategory === void 0 ? void 0 : selectedSubCategory.includes(item)) || false, sx: { width: '15px', height: '15px' } }), label: _jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx("span", __assign({ style: { paddingLeft: '8px' } }, { children: item })), filteredSubCategory && (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16), ml: 1 }, { children: "(".concat(careerSearchData &&
                                            ((_a = careerSearchData === null || careerSearchData === void 0 ? void 0 : careerSearchData.filter(function (item1) {
                                                var sanitizedSubCategory = removeDotFromStringEnd(item);
                                                return (item1[CareerActionTypes.CATEGORY] === category &&
                                                    item1[CareerActionTypes.SUBCATEGORY] ===
                                                        sanitizedSubCategory);
                                            })) === null || _a === void 0 ? void 0 : _a.length), ")") })))] })), sx: {
                                '& .MuiFormControlLabel-label': {
                                    fontSize: fontSizeDynamic(16),
                                },
                                pl: 2,
                            } }) }), item + index));
                })) }));
    };
    var category = function () {
        return (_jsx(_Fragment, { children: categoryLabels &&
                (categoryLabels === null || categoryLabels === void 0 ? void 0 : categoryLabels.map(function (item, index) {
                    var _a, _b, _c;
                    return (_jsxs(FormGroup, __assign({ sx: { pl: 4, pb: 1 }, onChange: function (e) {
                            return filterHandler(e, CareerActionTypes.CATEGORY);
                        } }, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { value: item, checked: ((_a = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.category) === null || _a === void 0 ? void 0 : _a.includes(item)) || false, sx: {
                                        width: '15px',
                                        height: '15px',
                                    } }), label: _jsxs("div", __assign({ style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                    } }, { children: [_jsx("span", __assign({ style: { paddingLeft: '8px' } }, { children: item })), categoryLabels && (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16), ml: 1 }, { children: "(".concat(careerSearchData &&
                                                ((_b = careerSearchData === null || careerSearchData === void 0 ? void 0 : careerSearchData.filter(function (item1) {
                                                    return item1[CareerActionTypes.CATEGORY] === item;
                                                })) === null || _b === void 0 ? void 0 : _b.length), ")") })))] })), inputMode: 'search', sx: {
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: fontSizeDynamic(16),
                                    },
                                } }), _jsx(Box, __assign({ pt: 1 }, { children: selectedFilterValues &&
                                    ((_c = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.category) === null || _c === void 0 ? void 0 : _c.includes(item)) &&
                                    subCategory(item) }))] }), item + index));
                })) }));
    };
    useEffect(function () {
        var _a, _b;
        if (((_a = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.category) === null || _a === void 0 ? void 0 : _a.length) &&
            (selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.category.includes(selectedSubCategory[0]))) {
            if (selectedSubCategory.includes(selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.category[0])) {
                setSelectedSubCategory([]);
                setSelectedFilterValues(function (prev) { return (__assign(__assign({}, prev), { category: [] })); });
            }
        }
        if (((_b = selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.state) === null || _b === void 0 ? void 0 : _b.length) &&
            (selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.state.includes(selectedCity[0]))) {
            if (selectedCity.includes(selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.state[0])) {
                setSelectedCity([]);
                setSelectedFilterValues(function (prev) { return (__assign(__assign({}, prev), { state: [] })); });
            }
        }
    }, [filteredDataMemo, selectedFilterValues]);
    // filter dropdown arrow with title
    var showHideArrow = function (show, sideHeaderTitle, clickItem) {
        return (_jsxs(Box, __assign({ sx: leftSelectionCss.boxStyle }, { children: [_jsx(Typography, __assign({ sx: leftSelectionCss.titleFont }, { children: sideHeaderTitle })), _jsx(Box, __assign({ onClick: function () { return handleShowAndHide(clickItem); }, sx: { cursor: 'pointer' } }, { children: _jsx(TPSvgIcon, { resourceName: show ? 'filer-up-arrow-icon' : 'filer-down-arrow-icon', id: "".concat(careerFilter, "-").concat(clickItem, "-dropDown-arrow"), "data-testid": "".concat(careerFilter, "-").concat(clickItem, "-dropDown-arrow") }) }))] })));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: leftSelectionCss.inputSearchHeader }, { children: [_jsx(Typography, __assign({ sx: leftSelectionCss.titleFont }, { children: "Search by Keyword" })), _jsx(TPTextInput, { type: 'text', name: 'Search', id: "".concat(careerFilter, "-keyword-search-input"), "data-testid": "".concat(careerFilter, "-keyword-search-input"), inputProps: {
                            'data-testid': 'career-search-input',
                            style: { fontSize: fontSizeDynamic(16) },
                        }, placeholder: 'Search...', variant: 'standard', value: searchValue, onChange: function (e) {
                            return handleInputKeySearch(e, CareerActionTypes.SEARCH);
                        } }), searchValue.length > 0 && filteredDataMemo.length === 0 && (_jsx(Typography, __assign({ className: 'error_message', id: 'career-search-error', "data-testid": 'career-search-error', fontSize: fontSizeDynamic(16) }, { children: "No search Results Found" })))] })), _jsxs(Box, __assign({ sx: leftSelectionCss.inputLocationHeader }, { children: [_jsx(Typography, __assign({ sx: leftSelectionCss.titleFont }, { children: "Search by Location" })), _jsx(TPTextInput, { type: 'text', name: 'location', id: "".concat(careerFilter, "-location-search-input"), "data-testid": "".concat(careerFilter, "-location-search-input"), inputProps: {
                            'data-testid': 'career-search-input',
                            style: { fontSize: fontSizeDynamic(16) },
                        }, placeholder: 'Location...', variant: 'standard', value: locationValue, onChange: function (e) {
                            return handleInputKeySearch(e, CareerActionTypes.LOCATION);
                        } }), locationValue.length > 0 && filteredDataMemo.length === 0 && (_jsx(Typography, __assign({ className: 'error_message', id: 'career-location-error', "data-testid": 'career-location-error', fontSize: fontSizeDynamic(16) }, { children: "No Results Found" })))] })), _jsxs(_Fragment, { children: [showHideArrow(showAndHide.jobStatus, 'Filter by Job Status', CareerActionTypes.JOB_STATUS), _jsx(Box, __assign({ pt: 2 }, { children: showAndHide.jobStatus && (_jsx(FormGroupCheckBox, { id: careerFilter, arrayList: jobStatus, checkBoxHandler: function (e) {
                                return filterHandler(e, CareerActionTypes.JOB_STATUS);
                            }, fontSize: 16, sx: { pl: 2, pb: 2 }, selectedArray: selectedFilterValues.jobStatus, checkBoxSize: '15px' })) }))] }), _jsxs(_Fragment, { children: [showHideArrow(showAndHide.category, 'Specialization', CareerActionTypes.CATEGORY), showAndHide.category && _jsx(Box, __assign({ pt: 2 }, { children: category() }))] }), _jsxs(_Fragment, { children: [showHideArrow(showAndHide.location, 'Filter by Locations', CareerActionTypes.LOCATION), showAndHide.location && _jsx(Box, __assign({ pt: 2 }, { children: stateList() }))] }), _jsxs(_Fragment, { children: [showHideArrow(showAndHide.workType, 'Work type', CareerActionTypes.WORK_TYPE), _jsx(Box, __assign({ pt: 2 }, { children: showAndHide.workType && (_jsx(FormGroupCheckBox, { id: 'workType', arrayList: workType, checkBoxHandler: function (e) {
                                return filterHandler(e, CareerActionTypes.WORK_TYPE);
                            }, fontSize: 16, sx: { pl: 2, pb: 2 }, selectedArray: selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.workType, checkBoxSize: '15px' })) }))] }), _jsxs(_Fragment, { children: [showHideArrow(showAndHide.workModel, 'Work Modal', CareerActionTypes.WORK_MODEL), _jsx(Box, __assign({ pt: 2 }, { children: showAndHide.workModel && (_jsx(FormGroupCheckBox, { id: 'workModal', arrayList: workModel, checkBoxHandler: function (e) {
                                return filterHandler(e, CareerActionTypes.WORK_MODEL);
                            }, fontSize: 16, sx: { pl: 2, pb: 2 }, selectedArray: selectedFilterValues === null || selectedFilterValues === void 0 ? void 0 : selectedFilterValues.workModel, checkBoxSize: '15px' })) }))] }), _jsxs(Box, __assign({ sx: { width: '100%', pt: 2 } }, { children: [_jsx(Typography, __assign({ sx: leftSelectionCss.titleFont }, { children: "Salary" })), _jsx(Box, __assign({ ml: 4 }, { children: _jsx(Slider, { value: salaryValue, valueLabelDisplay: 'auto', min: salaryRange.min, max: salaryRange.max, onChange: handleSalaryBar, sx: {
                                color: salaryValue[0] > 0 || salaryValue[1] < salaryRange.max
                                    ? 'none'
                                    : '#F7F8FA',
                                width: '80%',
                            } }) })), _jsx(Typography, __assign({ pt: 1, ml: '35%' }, { children: "".concat(salaryValue[0], " - ").concat(salaryValue[1]) }))] }))] }));
};
export default CareerLeftSelection;
