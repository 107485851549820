var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import './learningPathways.scss';
import { learningResourcesObject } from './learningResourceCardObject';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var LearningResources = function () {
    useEffect(function () {
        window.scrollTo(0, 0);
    }, []);
    var learningResources = 'learning-resources';
    var gridItem = {
        height: window.innerHeight <= 900 ? '10%' : '26%',
        display: 'flex',
        flexDirection: 'column',
    };
    var containerBox = { flex: 1, display: 'flex', flexDirection: 'column' };
    return (_jsx(Grid, __assign({ container: true, pb: 4, mt: 1 }, { children: _jsx(Grid, __assign({ container: true, spacing: 2, columns: 12 }, { children: learningResourcesObject.map(function (item, index) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, sx: gridItem }, { children: _jsx(Box, __assign({ className: 'learning-resource-container-box', sx: containerBox }, { children: _jsxs(Box, __assign({ className: 'learning-resource-container', sx: containerBox }, { children: [_jsxs(Box, __assign({ sx: { mb: 2 } }, { children: [_jsx(Typography, __assign({ className: 'learning-card-title', id: "".concat(learningResources, "-").concat(item.title), mb: 2, fontSize: fontSizeDynamic(16) }, { children: item.title })), _jsx(Typography, __assign({ className: 'learning-resource-description', id: "".concat(learningResources, "-").concat(item.title, "-description"), fontSize: fontSizeDynamic(16) }, { children: item.description }))] })), _jsx(Box, __assign({ sx: { mt: 'auto' } }, { children: _jsx(TPButton, __assign({ className: 'candidate-portal-button-clicks', color: 'primary', id: "".concat(learningResources, "-").concat(item.title, "-button"), "data-testid": "".concat(learningResources, "-").concat(item.title, "-button"), onClick: function () { return window.open(item.link, '_blank'); }, sx: { fontSize: fontSizeDynamic(14) } }, { children: item.buttonText })) }))] })) })) }), item.title + index)); }) })) })));
};
export default LearningResources;
