var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ContactBannerStyle, ContactBoxStyle } from './InfoCenter.styled';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Contact = function () {
    var _a = useState(true), renderComponent = _a[0], setRenderComponent = _a[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        setRenderComponent(entitlementsData.entitlementDto.candidateInfoCenterJson.pointsofContact);
    }, [entitlementsData]);
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsxs(ContactBannerStyle, { children: [_jsxs(Box, __assign({ className: 'banner img-1', id: 'contacts-banner-banner-1' }, { children: [_jsx(Typography, __assign({ variant: 'h2', id: 'contacts-banner-point', fontSize: fontSizeDynamic(32) }, { children: "Point of Contacts" })), _jsx(Typography, __assign({ variant: 'h6', id: 'contacts-banner-info', fontSize: fontSizeDynamic(18) }, { children: "Points of contact for payroll, benefits, and HR questions to quickly resolve pay issues, understand benefits options, and address work-related concerns, ensuring they have the support needed for a smooth and productive employment experience." }))] })), _jsxs(Grid, __assign({ container: true, spacing: 2, sx: { mb: '30px' } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12, md: 4, sx: { display: 'flex' } }, { children: _jsxs(ContactBoxStyle, __assign({ id: 'contacts-sicktime-box' }, { children: [_jsx(Typography, __assign({ variant: 'h3', id: 'contacts-sicktime-heading', fontSize: fontSizeDynamic(20) }, { children: "For assistance with the portal or questions related to iSolved, sick time, timesheets, or expense issues, please reach out to:" })), _jsx(Typography, __assign({ variant: 'h4', id: 'contacts-sicktime-info', fontSize: fontSizeDynamic(18) }, { children: _jsx(Link, __assign({ to: "mailto:candidatesupport@tandymgroup.com" }, { children: "candidatesupport@tandymgroup.com" })) }))] })) })), entitlementsData.entitlementDto.candidateInfoCenterJson
                        .contactBenefits && (_jsx(Grid, __assign({ item: true, xs: 12, sm: 12, md: 4, sx: { display: 'flex' } }, { children: _jsxs(ContactBoxStyle, __assign({ className: 'contact-grid-box', id: 'contacts-benefits-box' }, { children: [_jsx(Typography, __assign({ variant: 'h3', id: 'contacts-benefits-heading', fontSize: fontSizeDynamic(18) }, { children: "For any questions regarding benefits?" })), _jsx(Typography, __assign({ variant: 'h4', id: 'contacts-benefits-info', fontSize: fontSizeDynamic(18) }, { children: _jsx(Link, __assign({ to: "mailto:benefits@tandymgroup.com" }, { children: "Benefits@tandymgroup.com" })) }))] })) }))), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12, md: 4, sx: { display: 'flex' } }, { children: _jsxs(ContactBoxStyle, __assign({ className: 'contact-grid-box', id: 'placement-specific-box' }, { children: [_jsx(Typography, __assign({ variant: 'h3', id: 'placement-specific-heading', fontSize: fontSizeDynamic(18) }, { children: "For placement specific recruiter/onboarding expert" })), _jsx(Box, __assign({ sx: { mt: 2 } }, { children: _jsx(Link, __assign({ to: '/placements/placements-details' }, { children: _jsxs(TPButton, __assign({ className: 'candidate-portal-button-clicks', id: 'contact-specific-recruiter-button', color: 'primary', sx: { fontSize: fontSizeDynamic(14) } }, { children: ["Click Here", _jsx(TPSvgIcon, { resourceName: 'arrow-left-alt' })] })) })) }))] })) }))] }))] }));
};
export default Contact;
