import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
export var careerDetailsCss = {
    subHeadingBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
    },
    resumeHeader: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
        paddingTop: '2px',
    },
    resumeDescriptionHeaderTitle: {
        color: colorCodes.iconBackgroundColor.royalBlue,
        fontSize: fontSizeDynamic(24),
        fontWeight: 400,
        paddingTop: '2px',
    },
    subHeading: {
        fontSize: fontSizeDynamic(24),
        fontWeight: 600,
    },
    detailsHeaderTexts: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 300,
        fontFamily: 'Public Sans, sans-serif',
    },
    detailesDescriptionText: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 300,
        fontFamily: 'Public Sans, sans-serif',
        lineHeight: '21px',
        color: colorCodes.textColors.Iridium,
    },
    detailsTexts: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    chipText: {
        fontSize: fontSizeDynamic(12),
        fontWeight: 300,
        color: colorCodes.iconBackgroundColor.royalBlue,
        border: "1px solid ".concat(colorCodes.iconBackgroundColor.royalBlue),
        width: '80px',
    },
    careerDetailsHeaderRSText: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 300,
    },
    careerDetailsHeaderRSBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        marginBottom: 2,
    },
    careerDetailsHeaderRSResume: {
        fontSize: fontSizeDynamic(13),
        fontWeight: 400,
        textDecoration: 'underline',
        lineHeight: '21px',
    },
    careerDetailsHeader: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
    },
};
