var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Tabs from '../../../../tandym-web-common/src/components/Tabs';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { InfoCenterStyle } from './InfoCenter.styled';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var InfoCenter = function () {
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _a = useState([]), tabs = _a[0], setTabs = _a[1];
    var _b = useState(true), renderComponent = _b[0], setRenderComponent = _b[1];
    var isScreenSmall = window.innerWidth <= 900;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'WHO WE ARE',
                    path: '/info-center/who-we-are',
                    show: entitlementsData.entitlementDto.candidateInfoCenterJson
                        .aboutTandym,
                },
                {
                    label: 'TRAINING',
                    path: '/info-center/training',
                    show: (_b = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.candidateInfoCenterJson) === null || _b === void 0 ? void 0 : _b.complianceTraining,
                },
                {
                    label: 'TIME REPORTING',
                    path: '/info-center/time-reporting',
                    show: (_c = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _c === void 0 ? void 0 : _c.timesheets,
                },
                {
                    label: 'RESOURCES & POLICIES',
                    path: '/info-center/resources-policies',
                    show: true,
                },
                {
                    label: 'LEARNING',
                    path: '/info-center/learn',
                    show: isScreenSmall
                        ? false
                        : (_e = (_d = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _d === void 0 ? void 0 : _d.candidateInfoCenterJson) === null || _e === void 0 ? void 0 : _e.learning,
                },
                {
                    label: 'CONTACT',
                    path: '/info-center/contact',
                    show: (_g = (_f = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _f === void 0 ? void 0 : _f.candidateInfoCenterJson) === null || _g === void 0 ? void 0 : _g.pointsofContact,
                },
            ]);
        }
        setRenderComponent((_j = (_h = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _h === void 0 ? void 0 : _h.candidateInfoCenterJson) === null || _j === void 0 ? void 0 : _j.aboutTandym);
    }, [entitlementsData]);
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: "Candidate Info Center ".concat(label, " tab"),
        });
    };
    var handleFaqClick = function () {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: 'Faq link',
        });
    };
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    return (_jsx(InfoCenterStyle, { children: _jsxs(Box, __assign({ className: 'sub-route-section info-center' }, { children: [_jsxs(Box, __assign({ sx: { mb: '20px' } }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ className: 'sub-routes-header', fontSize: fontSizeDynamic(24) }, { children: "Information Center" })), !isScreenSmall && (_jsxs(Typography, __assign({ className: 'faq-link-section' }, { children: [_jsx("span", __assign({ className: 'need-help' }, { children: "Need help? " })), _jsx(Link, __assign({ id: 'candidate-faq', to: '/faqs', className: 'blue-text text-decoration-none candidate-portal-button-clicks', onClick: handleFaqClick, style: { fontSize: fontSizeDynamic(16) } }, { children: "FAQ" }))] })))] })), _jsx(Box, __assign({ className: 'sub-tab-header-section' }, { children: _jsx(Tabs, { headerTabs: tabs, tabSize: 'small', tabClick: handleClickedTab }) })), _jsx(Box, __assign({ sx: { px: '5px', py: '10px' } }, { children: _jsx(Outlet, {}) }))] })) }));
};
export default InfoCenter;
