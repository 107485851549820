import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
export var careerCss = {
    mainGrid: {
        margin: '1%',
        padding: '1%',
        width: '96%',
    },
    sortByBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
    },
    gridStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        mt: 2,
    },
    gridSplit: {
        backgroundColor: '#ffffff',
        p: 3,
    },
    sortBox: {
        border: '2px solid black',
        p: 1,
        position: 'absolute',
        backgroundColor: 'white',
    },
    sortButton: {
        position: 'absolute',
        right: '4%',
        p: 1,
        zIndex: 1,
        borderRadius: 3,
    },
    sortingBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3,
    },
    subText: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
        width: 'max-content',
    },
    formControl: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rightPanel: {
        marginLeft: '2%',
        backgroundColor: '#ffffff',
    },
};
export var leftSelectionCss = {
    titleFont: { fontSize: fontSizeDynamic(16), fontWeight: 700 },
    boxStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 2,
    },
    inputSearchHeader: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
    },
    inputLocationHeader: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
        mt: 2,
    },
};
export var careerCardCss = {
    headerText: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    htmlDescription: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
        lineHeight: 2,
        pt: 2,
        cursor: 'pointer',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Public Sans",sans-serif',
    },
    chipsText: {
        fontSize: fontSizeDynamic(12),
        fontWeight: 300,
        color: colorCodes.iconBackgroundColor.royalBlue,
        border: "1px solid ".concat(colorCodes.iconBackgroundColor.royalBlue),
        width: '80px',
    },
    commonFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    mainCard: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        p: 2,
    },
    noJobsFound: {
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center',
        pt: 3,
    },
};
export var appliedJobsCss = {
    noJobsFound: {
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center',
        pt: 3,
    },
};
