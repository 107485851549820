var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './Evidence.scss';
import TPTextInput from '../../../../../tandym-web-common/src/shared/components/textinput/TPTextInput';
import { ComplianceStatus, } from '../../../shared/models/Compliance';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import CloseIcon from '@mui/icons-material/Close';
import UploadImg from '../../../../../tandym-web-common/src/images/cloud_upload.png';
import { TANDYM_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../../../tandym-web-common/src/shared/services/api/API';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
var Evidence = function (_a) {
    var evidenceObj = _a.evidenceObj, placementId = _a.placementId, setIsLoading = _a.setIsLoading, setSuccessMessage = _a.setSuccessMessage, setErrorMessage = _a.setErrorMessage, fetchComplianceData = _a.fetchComplianceData;
    var name = evidenceObj.name, validFrom = evidenceObj.validFrom, validTo = evidenceObj.validTo, status = evidenceObj.status, id = evidenceObj.id;
    var httpPut = useAPI().httpPut;
    var fileInputRef = useRef(null);
    var _b = useState(null), evidence = _b[0], setEvidence = _b[1];
    var _c = useState(dayjs.utc(validFrom)), validFromDate = _c[0], setValidFromDate = _c[1];
    var _d = useState(true), btnDisabled = _d[0], setBtnDisabled = _d[1];
    var _e = useState(dayjs.utc(validTo)), validToDate = _e[0], setValidToDate = _e[1];
    var _f = useState(null), error = _f[0], setError = _f[1];
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    var handleFileChange = function (file) { return setEvidence(file); };
    var handleDragOver = function (event) {
        return event.preventDefault();
    };
    var handleDrop = function (event) {
        event.preventDefault();
        handleFileChange(event.dataTransfer.files[0]);
    };
    var handleInputFileChange = function (event) {
        if (event.target.files) {
            handleFileChange(event.target.files[0]);
        }
    };
    var validateDates = function (from, to) {
        if (from && to && from.isAfter(to)) {
            setError('Valid From date cannot be after Valid To date.');
        }
        else {
            setError(null);
        }
    };
    var handleValidFromChange = function (date) {
        setValidFromDate(date);
        validateDates(date, validToDate);
    };
    var handleValidToChange = function (date) {
        setValidToDate(date);
        validateDates(validFromDate, date);
    };
    var getComplianceStatus = function (status) {
        return ComplianceStatus[status];
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var validStatus, formData, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setSuccessMessage('');
                    setLoading(true);
                    validStatus = getComplianceStatus(status);
                    formData = new FormData();
                    if (evidence)
                        formData.append('evidenceFile', evidence);
                    formData.append('Status', validStatus);
                    formData.append('validFrom', (validFromDate === null || validFromDate === void 0 ? void 0 : validFromDate.format('MM/DD/YYYY')) || '');
                    formData.append('validTo', (validToDate === null || validToDate === void 0 ? void 0 : validToDate.format('MM/DD/YYYY')) || '');
                    formData.append('placementsId', placementId !== null && placementId !== void 0 ? placementId : '');
                    return [4 /*yield*/, httpPut("".concat(TANDYM_CONSTANTS.API_URLS.CANDIDATE_COMPLIANCE_UPLOAD_EVIDENCE_POST, "/").concat(id), formData, undefined, true, true)];
                case 1:
                    response = _a.sent();
                    if ((response === null || response === void 0 ? void 0 : response.error) === false) {
                        setSuccessMessage(response.message);
                        setTimeout(function () {
                            fetchComplianceData();
                        }, 2000);
                    }
                    else if (response.error === true) {
                        setErrorMessage(response.message);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    setError('Error submitting evidence');
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var validateForm = function () {
        var isValidDate = function (date) {
            return date !== null && date.isValid();
        };
        if (evidence &&
            isValidDate(validFromDate) &&
            isValidDate(validToDate) &&
            !error) {
            setBtnDisabled(false);
        }
        else {
            setBtnDisabled(true);
        }
    };
    useEffect(function () {
        validateForm();
    }, [evidence, validFromDate, validToDate, error]);
    return (_jsxs(Box, __assign({ className: 'evidence', id: 'evidence', "data-testid": 'evidence' }, { children: [_jsxs(Box, __assign({ sx: { position: 'relative' } }, { children: [_jsx(Typography, __assign({ className: 'evidence-header', fontSize: fontSizeDynamic(20) }, { children: "Submit Evidence" })), _jsx(CloseIcon, { id: 'evidence-cross-close-icon', "data-testid": 'evidence-cross-close-icon', className: 'evidence-cross-close-icon', onClick: function () { return setIsLoading(false); } })] })), _jsx(Grid, __assign({ container: true, spacing: 2, sx: { mt: 2 } }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(TPTextInput, { className: 'evidence-name', id: 'evidence-name', "data-testid": 'evidence-name', value: name, label: 'Criteria', InputLabelProps: {
                            className: 'formLabel',
                            style: { fontSize: fontSizeDynamic(16) },
                        }, variant: 'standard', inputProps: { className: 'evidence-name-input' } }) })) })), _jsxs(Grid, __assign({ container: true, spacing: 3, sx: { mt: 2 } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: validFromDate, onChange: handleValidFromChange, label: 'Valid From', sx: { width: '100%' }, maxDate: dayjs(), slotProps: {
                                    textField: {
                                        variant: 'standard',
                                        InputLabelProps: { className: 'formLabel' },
                                        inputProps: {
                                            className: 'evidence-date-picker',
                                            id: 'evidence-validFrom-date-picker',
                                            'data-testid': 'evidence-validFrom-date-picker',
                                            style: { fontSize: fontSizeDynamic(16) },
                                        },
                                    },
                                }, slots: {
                                    openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: 'validFrom-date-picker-icon', "data-testid": 'validFrom-date-picker-icon' })); },
                                } }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: validToDate, onChange: handleValidToChange, label: 'Valid To', sx: { width: '100%' }, minDate: dayjs(), slotProps: {
                                    textField: {
                                        variant: 'standard',
                                        InputLabelProps: { className: 'formLabel' },
                                        inputProps: {
                                            className: 'evidence-date-picker',
                                            id: 'evidence-validTo-date-picker',
                                            'data-testid': 'evidence-validTo-date-picker',
                                            style: { fontSize: fontSizeDynamic(16) },
                                        },
                                    },
                                }, slots: {
                                    openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: 'validTo-date-picker-icon', "data-testid": 'validTo-date-picker-icon' })); },
                                } }) })) }))] })), error && (_jsx(Typography, __assign({ color: 'error', id: 'error-message', "data-testid": 'error-message', className: 'error_message' }, { children: error }))), _jsxs(Box, __assign({ className: 'evidence-upload', sx: { mt: 5 } }, { children: [_jsx(Typography, __assign({ className: 'evidence-upload-file', fontSize: fontSizeDynamic(16) }, { children: "Upload Evidence File" })), _jsx(Box, __assign({ className: 'evidence-drag-drop-upload', onDrop: handleDrop, onDragOver: handleDragOver, onClick: function () { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: _jsxs(Box, __assign({ className: 'evidence-upload-info' }, { children: [_jsx("img", { src: UploadImg, alt: 'upload-img', id: 'upload-img', "data-testid": 'upload-img', width: window.innerWidth / 50, height: window.innerWidth / 50 }), _jsx("input", { type: 'file', accept: '.pdf,image/*', ref: fileInputRef, onChange: handleInputFileChange, style: { display: 'none', fontSize: fontSizeDynamic(14) }, "data-testid": 'file-upload', id: 'file-upload' }), _jsx(Typography, __assign({ className: 'evidence-dragToUpload', fontSize: fontSizeDynamic(16) }, { children: evidence
                                        ? "".concat(evidence.name, " uploaded")
                                        : 'Drag & Drop or Click to Upload' })), _jsx(Typography, __assign({ className: 'evidence-browse', fontSize: fontSizeDynamic(14) }, { children: "or Browse" }))] })) }))] })), _jsx(Grid, __assign({ container: true, justifyContent: 'flex-end', sx: { mt: 6 } }, { children: _jsxs(Grid, __assign({ item: true }, { children: [_jsx(TPButton, __assign({ id: 'cancel-evidence-button', "data-testid": 'cancel-evidence-button', color: 'secondary', sx: { mr: 2, fontSize: fontSizeDynamic(14) }, onClick: function () { return setIsLoading(false); } }, { children: "Cancel" })), _jsx(TPButton, __assign({ className: 'candidate-portal-button-clicks', id: 'evidence-submit-button', "data-testid": 'evidence-submit-button', color: 'primary', disabled: btnDisabled, onClick: handleSubmit, sx: { fontSize: fontSizeDynamic(14) } }, { children: "Submit" }))] })) })), loading && _jsx(TPProgressModal, { isLoading: loading })] })));
};
export default Evidence;
