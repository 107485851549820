var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './CandidateUpdate.scss';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPCard from '../../../../tandym-web-common/src/shared/components/card/TPCard';
import { useNavigate } from 'react-router-dom';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { useFileUpload } from '../../utilities/API';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { isMobile, isMobileEntitlementFlag, } from '../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
import { useShowContext } from '../../pages/RootLayout';
var CandidateUpdate = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var profileData = _a.profileData, entitlementsData = _a.entitlementsData, showUploadResume = _a.showUploadResume;
    var navigate = useNavigate();
    var fileInputRef = useRef(null);
    var _p = useState(false), showMore = _p[0], setShowMore = _p[1];
    var _q = useFileUpload(), fileChange = _q.fileChange, errorMessage = _q.errorMessage, isLoading = _q.isLoading, successMessage = _q.successMessage;
    var isSmallScreen = window.innerWidth <= 600;
    var isMediumScreen = window.innerWidth <= 900;
    var setIsShow = useShowContext().setIsShow;
    var fullName = profileData
        ? "".concat((_c = (_b = profileData.generalDetails) === null || _b === void 0 ? void 0 : _b.firstName) !== null && _c !== void 0 ? _c : '', " ").concat((_e = (_d = profileData.generalDetails) === null || _d === void 0 ? void 0 : _d.lastName) !== null && _e !== void 0 ? _e : '')
        : '';
    var location = profileData
        ? [
            (_f = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _f === void 0 ? void 0 : _f.city,
            (_g = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _g === void 0 ? void 0 : _g.state,
            (_h = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _h === void 0 ? void 0 : _h.country,
        ]
            .filter(Boolean)
            .join(', ')
        : '';
    // const uploadResume = () => {
    //   if (fileInputRef.current) fileInputRef.current.click();
    // };
    var navigationHandler = function (path, actionTitle, externalUrl) {
        if (externalUrl && externalUrl !== 'none') {
            window.open(externalUrl, '_blank', 'width=1000,height=700,top=20,left=20');
        }
        else {
            ReactGA.event({
                category: 'Candidate',
                action: 'Click',
                label: "".concat(actionTitle, " action button"),
            });
            navigate(new URL(path).pathname);
        }
    };
    var handleFileChange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            fileChange(event);
            return [2 /*return*/];
        });
    }); };
    var renderActionCards = function () {
        var _a, _b, _c;
        var isTimesheet = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.callForActions) === null || _a === void 0 ? void 0 : _a.some(function (item) {
            return item.internalPageId.includes('timesheets-expenses');
        });
        var showNoCallToAction = (isMobile() && !isTimesheet) || !((_b = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.callForActions) === null || _b === void 0 ? void 0 : _b.length);
        if (showNoCallToAction) {
            return (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ sx: { textAlign: 'center', fontSize: fontSizeDynamic(16) }, className: 'no-candidate-update-info', "data-testid": 'no-candidate-update-info' }, { children: "No Call of Actions available." })) })));
        }
        var updatedEntitlementsData = isMobile()
            ? (_c = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.callForActions) === null || _c === void 0 ? void 0 : _c.filter(function (action) {
                return action.internalPageId.includes('timesheets-expenses');
            })
            : entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.callForActions;
        return updatedEntitlementsData.map(function (action, index) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(TPCard, __assign({ customStyles: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsxs(Box, __assign({ className: 'candidate-card-text-header' }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon', className: 'small-vertical-line-icon', width: isSmallScreen ? 16 : 24, height: isSmallScreen ? 20 : 30 }), _jsx(Typography, __assign({ className: 'candidate-update-card-title', fontSize: fontSizeDynamic(20) }, { children: action.title }))] })), _jsx(Typography, __assign({ className: 'candidate-update-info-text', fontSize: fontSizeDynamic(16) }, { children: action.description }))] })), action.actionButtonName && (_jsx(Box, __assign({ className: 'candidate-card-button' }, { children: _jsx(TPButton, __assign({ className: 'candidate-portal-button-clicks', id: "candidate-call-for-actions-".concat(action.name, "-").concat(action.actionButtonName, "-button"), color: 'primary', sx: {
                                borderRadius: 0,
                                fontSize: isMediumScreen ? '0.75rem' : fontSizeDynamic(16),
                            }, onClick: function () {
                                return navigationHandler(action.internalPageId, action.title, action.externalUrl);
                            } }, { children: action.actionButtonName })) })))] })) }), index)); });
    };
    return (_jsxs(Box, __assign({ id: 'candidate-update', "data-testid": 'candidate-update' }, { children: [_jsxs(Box, __assign({ className: 'candidate-update-profile' }, { children: [_jsxs(Typography, __assign({ className: 'candidate-update-name', fontSize: fontSizeDynamic(32) }, { children: ["Welcome ", fullName, ","] })), _jsx(Typography, __assign({ className: 'candidate-update-info', fontSize: fontSizeDynamic(16) }, { children: "Stay connected, track progress, and grow with tailored resources just for you." })), (!isMobile() ||
                        (isMobile() &&
                            isMobileEntitlementFlag('profile', entitlementsData))) && (_jsx(_Fragment, { children: _jsx(TPButton, __assign({ color: 'primary', sx: {
                                fontSize: isMediumScreen ? '0.75rem' : fontSizeDynamic(16),
                                mt: '32px',
                            }, onClick: function () { return navigate('/profile/personal-details'); } }, { children: "Update My Profile" })) })), (!isMobile() &&
                        ((_k = (_j = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _j === void 0 ? void 0 : _j.learningJson) === null || _k === void 0 ? void 0 : _k.coachBot)) ||
                        (isMobile() &&
                            ((_o = (_m = (_l = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _l === void 0 ? void 0 : _l.feMobileEntitlement) === null || _m === void 0 ? void 0 : _m.learningJson) === null || _o === void 0 ? void 0 : _o.coachBot)) ? (_jsx(TPButton, __assign({ className: 'candidate-portal-button-clicks', id: 'tandym-chat-button', color: 'primary', sx: {
                            fontSize: isMediumScreen ? '0.75rem' : fontSizeDynamic(16),
                            mt: '32px',
                            ml: '16px',
                        }, onClick: function () { return setIsShow(true); } }, { children: "Chat with Coach Bot" }))) : null] })), isMobile() && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ sx: { mb: '1rem', color: '#2a70ea', px: '8px', pt: '8px' } }, { children: "The mobile version of the Tandym Portal is primarily for entry of timesheets and expenses." })), _jsx(Typography, __assign({ sx: { mb: '1rem', px: '8px', fontSize: '0.8rem' } }, { children: "For features like profile updates, placements, compliance evidence submission, the Tandym info center and learning through Tandym Coach, please visit the Tandym Portal from desktop / laptop." })), _jsxs(Typography, __assign({ sx: { mb: '1rem', px: '8px', fontSize: '0.8rem' } }, { children: ["For support contact", ' ', _jsx("a", __assign({ style: { textDecoration: 'none' }, href: "mailto:candidatesupport@tandymgroup.com" }, { children: "candidatesupport@tandymgroup.com" }))] }))] })), _jsx(Grid, __assign({ container: true, spacing: 2, sx: { mt: 3 } }, { children: renderActionCards() })), _jsx("input", { type: 'file', "data-testid": 'candidate-update-file-input', ref: fileInputRef, className: 'upload-resume', onChange: handleFileChange }), isLoading && _jsx(TPProgressModal, { isLoading: isLoading })] })));
};
export default CandidateUpdate;
